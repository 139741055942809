import {
  initializeBootstrapComponents,
  closeOpenModals,
  deleteDuplicateModalBackdrop,
  resizeModal,
 } from './index'; // Assurez-vous que le chemin est correct

// Fonction pour réinitialiser la page après soumission
export function refreshPage() {
  var submitSuccessElement = document.querySelector('#rating_form');
  if (submitSuccessElement) {
    setTimeout(() => {
      var submitSuccess = submitSuccessElement.getAttribute('data-submit-success');
      if (submitSuccess == "true") {
        location.reload();
      }
    }, 2000);
  }
}

// Configuration des écouteurs d'événements Turbo
export function setupTurboListeners() {
  document.addEventListener("turbo:load", () => {
    initializeBootstrapComponents();
    deleteDuplicateModalBackdrop();
    // closeOpenModals(); // Commenté à cause de suggested_companies_modal_controller.js
    resizeModal();
  });

  document.addEventListener("turbo:before-cache", () => {
    // closeOpenModals(); // Commenté à cause de suggested_companies_modal_controller.js
  });

  document.addEventListener("turbo:after-stream-render", () => {
    initializeBootstrapComponents();
  });

  document.addEventListener("turbo:frame-load", () => {
    resizeModal();
    initializeBootstrapComponents();
  });

  document.addEventListener("turbo:submit-end", () => {
    setTimeout(() => {
      refreshPage();
      resizeModal();
      initializeBootstrapComponents();
    }, 1000);
  });
}
