import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import i18n from "../helpers/i18n_config";

// Connects to data-controller="docusign-commercial-contract-signature"
export default class extends Controller {
  static values = {
    companyId: Number,
    contractSigned: Boolean,
  }

  connect() {
    if (this.contractSignedValue) {
      this.commercialContractSignatureSuccess();
    } else {
      this.commercialContractSignatureAlert();
    }
  }

  commercialContractSignatureAlert() {
    Swal.fire({
      title: i18n.t("docusigns.commercial_contract_signature.title_html"),
      html: i18n.t("docusigns.commercial_contract_signature.text_html"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Signer le contrat',
      cancelButtonText: 'Retour',
      buttonsStyling: false, // Désactiver les styles par défaut
      customClass: {
        confirmButton: 'btn btn-success text-white me-2',
        cancelButton: 'btn btn-danger text-white'
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirige l'utilisateur vers l'action billing
        window.location.href = `/companies/${this.companyIdValue}/docusigns/commercial_contract_signature`;
      }
    });
  }

  commercialContractSignatureSuccess() {
    Swal.fire({
      title: i18n.t("docusigns.commercial_contract_signature.success_title_html"),
      html: i18n.t("docusigns.commercial_contract_signature.success_text_html"),
      icon: 'success',  // Icône de succès
      confirmButtonText: i18n.t("shared.close"),
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-success text-white'
      },
      allowOutsideClick: true,
      allowEscapeKey: true
    });
  }

  disconnect() {
    // Ferme toutes les SweetAlert2 actives lors de la déconnexion du controller
    Swal.close();
  }
}
