import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config"

// Connects to data-controller="quote-amount-pending"
export default class extends Controller {
  static values = {
    quotePath: String
  }

  connect() {
    if (this.hasQuotePathValue) {
      this.companyQuoteAmountAlert()
    } else {
      this.scrollToElement()
    }
  }

  companyQuoteAmountAlert() {
    Swal.fire({
      title: i18n.t("docusigns.quote_amout_pending_alert.title"),
      html: i18n.t("docusigns.quote_amout_pending_alert.text_html"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: i18n.t("shared.declare_the_amount"),
      cancelButtonText: i18n.t("shared.back"),
      buttonsStyling: false, // Désactiver les styles par défaut
      customClass: {
        confirmButton: 'btn btn-success text-white me-2',
        cancelButton: 'btn btn-danger text-white'
      },
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        // Redirige l'utilisateur vers l'action billing
        window.location.href = this.quotePathValue;
      }
    });
  }

  scrollToElement() {
    setTimeout(() => {
      const hash = window.location.hash;
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        const yOffset = -90;  // Décalage de 90 pixels vers le haut
        const y = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      } else {
        console.log("Target element not found:", hash);
      }
    }, 100);  // Délai pour s'assurer que tous les éléments sont chargés
  }
}
