import { Controller } from "@hotwired/stimulus"
import Swal from "sweetalert2"
import i18n from "../helpers/i18n_config";

// Connects to data-controller="sweet-alert"
export default class extends Controller {
  static targets = ["radioInput"]

  static values = {
    page: String,
    icon: String,
    title: String,
    html: String,
    display: Boolean,
    doNotDisplayColumn: String,
  }

  connect() {
    this.checkSessionIdAndShowAlert();
  }

  checkSessionIdAndShowAlert() {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    if (sessionId) {
      Swal.fire({
        icon: this.iconValue,
        title: this.titleValue,
        html: this.htmlValue,
        showConfirmButton:      true,      // Affiche le bouton de confirmation
        allowOutsideClick:      false,     // Empêche la fermeture en cliquant à l'extérieur de l'alerte
        allowEscapeKey:         false,     // Empêche la fermeture avec la touche Echap
        allowEnterKey:          false,     // Empêche la fermeture avec la touche Entrée
        stopKeydownPropagation: false,     // Pour ne pas arrêter la propagation d'événements clavier (ajuster selon le besoin)
        showCancelButton:       false,     // Cache le bouton d'annulation s'il est présent
        // Autres configurations...
      });
    } else if (this.displayValue) {
      Swal.fire({
        icon: this.iconValue,
        title: this.titleValue,
        html: this.htmlValue,
        showConfirmButton: true, // Affiche le bouton de confirmation
        allowOutsideClick: false, // Empêche la fermeture en cliquant à l'extérieur de l'alerte
        allowEscapeKey: false, // Empêche la fermeture avec la touche Echap
        allowEnterKey: false, // Empêche la fermeture avec la touche Entrée
        stopKeydownPropagation: false, // Pour ne pas arrêter la propagation d'événements clavier (ajuster selon le besoin)
        showCancelButton: false, // Cache le bouton d'annulation s'il est présent
        footer: `<label><input type="checkbox" id="no-more-display" /> ${i18n.t('sweet_alert.hide_forever')}</label>`,
        preConfirm: () => {
          const noMoreDisplay = document.getElementById('no-more-display').checked;
          return { noMoreDisplay };
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.noMoreDisplay) {
            this.updateNoMoreDisplay();
          }
        }
      });
    }
  }

  confirmNotarySubmit(event) {
    event.preventDefault();
    const selectedRadio = this.radioInputTargets.find(radio => radio.checked);

    if ((selectedRadio && selectedRadio.value === "other_notary_for_buyer") || (selectedRadio && selectedRadio.value === "other_notary_for_owner")) {
      Swal.fire({
        icon: 'warning',
        title: i18n.t("purchase_negociations.edit.choose_you_notary"),
        html: i18n.t("purchase_negociations.edit.are_you_sure_of_your_notary_choice"),
        showCancelButton: true,
        buttonsStyling: false, // Désactiver les styles par défaut
        customClass: {
          confirmButton: 'btn btn-success text-white me-2',
          cancelButton: 'btn btn-danger text-white'
        },
        confirmButtonText: i18n.t("sweet_alert.notary_confirm"),
        cancelButtonText: i18n.t("sweet_alert.cancel")
      }).then((result) => {
        if (result.isConfirmed) {
          event.target.submit();
        }
      });
    } else {
      event.target.submit();
    }
  }

  updateNoMoreDisplay() {
    const columnToUpdate = this.doNotDisplayColumnValue;

    if (!columnToUpdate) {
      console.error('No column to update specified');
      return;
    }

    const body = {};
    body[columnToUpdate] = true;

    fetch(`/do_not_displays/update_${columnToUpdate}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name=csrf-token]').content
      },
      body: JSON.stringify(body)
    })
    .then(response => response.json())
    .then(data => {
      if (data.status === 'success') {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-success text-white',
            cancelButton: 'btn btn-danger text-white'
          },
          buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
          icon: 'success',
          title: i18n.t("sweet_alert.profile_updated"),
          text: i18n.t("sweet_alert.window_will_not_show_again"),
          showConfirmButton: true,
          confirmButtonText: 'OK',
        });
      } else {
        console.error('Failed to update preference');
      }
    })
    .catch(error => console.error('Error:', error));
  }
}
