import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

// Connects to data-controller="open-bootstrap-modal"
export default class extends Controller {
  connect() {
    // ouvrir la modale Bootstrap avec id="quoteRatingModal"
    let modal = new bootstrap.Modal(document.getElementById("quoteRatingModal"))
    if (modal) {
      modal.show()
    }
  }
}
