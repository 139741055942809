import { Controller } from "@hotwired/stimulus"
import i18n from "../helpers/i18n_config";

export default class extends Controller {
  static targets = ["notifications"]
  static values = { userData: Object }

  connect() {
    this.checkNotifications();
  }

  checkNotifications() {
    const csrfTokenMetaTag = document.querySelector("meta[name='csrf-token']");
    if (!csrfTokenMetaTag) {
      console.error("CSRF token meta tag not found");
      return;
    }

    fetch("/notifications/check", {
      headers: {
        "X-CSRF-Token": csrfTokenMetaTag.getAttribute("content"),
        "Accept": "application/json"
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.updateNotificationsIcon(data.has_unread);
    });
  }

  updateNotificationsIcon(hasUnread) {
    const notificationIcon = document.getElementById('notificationIcon');
    if (hasUnread) {
      notificationIcon.classList.add('fa-beat', 'text-danger');
    } else {
      this.notificationsTarget.innerHTML = `<li class="dropdown-item text-muted">${i18n.t('notifications.no_notification')}</li>`;
      notificationIcon.classList.remove('fa-beat', 'text-danger');
    }
    notificationIcon.style.visibility = 'visible';
  }
}
