import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import i18n from "../helpers/i18n_config";

// Connects to data-controller="company-update-form-submission"
export default class extends Controller {
  static values = {
    jobIds: Array  // Utiliser un tableau pour récupérer les job_ids initialement associés à l'entreprise
  }

  connect() {
    // Récupérer l'élément job_ids du formulaire
    this.jobSelect = document.getElementById("job_ids");

    // Vérifier si l'élément existe
    if (!this.jobSelect) {
      console.error("job_ids field not found");
      return;
    }

    // Stocker les valeurs initiales du champ job_ids dans le formulaire
    this.initialJobValue = new Set(this.jobIdsValue.map(id => String(id)));  // Assurez-vous que les valeurs sont des chaînes
  }

  confirmSubmission(event) {
    event.preventDefault();

    // Récupérer les valeurs actuelles sélectionnées dans le champ job_ids
    const currentJobValue = new Set([...this.jobSelect.options]
      .filter(option => option.selected)
      .map(option => option.value));

    // Comparer les ensembles : Set ne prend pas en compte l'ordre des éléments
    const isJobChanged = !this.areSetsEqual(this.initialJobValue, currentJobValue);

    // Si la sélection a changé, afficher l'alerte
    if (isJobChanged) {
      Swal.fire({
        title: i18n.t("companies.edit.sweet_alert.title_html"),
        html: i18n.t("companies.edit.sweet_alert.text_html"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t("shared.accept_button"),
        cancelButtonText: i18n.t("shared.cancel_button"),
        buttonsStyling: false, // Désactiver les styles par défaut
        customClass: {
          confirmButton: 'btn btn-success text-white me-2',
          cancelButton: 'btn btn-danger text-white'
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.element.submit();  // Soumettre le formulaire si l'utilisateur confirme
        }
      });
    } else {
      // Si aucune modification n'a été détectée, soumettre directement le formulaire
      this.element.submit();
    }
  }

  // Méthode utilitaire pour comparer deux ensembles
  areSetsEqual(setA, setB) {
    if (setA.size !== setB.size) return false;
    for (let item of setA) {
      if (!setB.has(item)) return false;
    }
    return true;
  }
}
