import { Controller } from "@hotwired/stimulus";
import Modal from "bootstrap/js/dist/modal";

export default class extends Controller {
  // static values = {
  //   controllerName: String,
  //   actionName: String
  // }

  // connect() {
  //   this.modal = new Modal(this.element.querySelector('#quoteSuggestedCompaniesModal'));
  //   this.checkAndOpenModal();
  // }

  // checkAndOpenModal() {
  //   // if (this.controllerNameValue === "users" && this.actionNameValue === "edit") {
  //   //   this.openModal();
  //   // }
  // }

  // openModal() {
  //   this.modal.show();
  // }
}
