import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="message"
export default class extends Controller {
  static values = { userId: Number }


  connect() {
    // triggered when a new message is added to the page
    const currentUserId = parseInt(document.body.dataset.currentUserId, 10);
    if (this.userIdValue === currentUserId) {
      this.element.classList.add('sent');
      this.element.classList.remove('received');
    } else {
      this.element.classList.add('received');
      this.element.classList.remove('sent');
    }

    // Cible la div "messages" pour faire défiler vers le bas de cette div
    const messageContainer = document.getElementById('messages');
    if (messageContainer) {
      // Sélectionne le dernier enfant de la div "messages" pour faire défiler jusqu'à lui
      const lastMessage = messageContainer.lastElementChild;
      if (lastMessage) {
        lastMessage.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        });
      }
    }

    const emptyMessageContainer = document.getElementById('no-messages');
    if (emptyMessageContainer) {
      emptyMessageContainer.classList.add('hidden');
    }
  }
}
