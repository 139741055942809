// import * as bootstrap from "bootstrap";
import { Controller } from "@hotwired/stimulus";
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import { hits, refinementList, rangeInput, clearRefinements, pagination, configure } from 'instantsearch.js/es/widgets';
import { connectStats, connectGeoSearch } from 'instantsearch.js/es/connectors';
import { history } from 'instantsearch.js/es/lib/routers';
import pluralize from 'pluralize';
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import mapboxgl from 'mapbox-gl';
import i18n from "../helpers/i18n_config";
import Swiper from 'swiper/bundle';
import * as bootstrap from "bootstrap"
import NProgress from 'nprogress'
import 'nprogress/nprogress.css';

let map = null;
let markers = [];
let isMobileOrTablet = window.innerWidth <= 992;

export default class extends Controller {
  static targets = [
    "cards",
    "flipCard",
    "defaultMarker",
    "priceMarker",
    "map",
    "propertyDetailsMap",
    "propertyDetailsList",
    "suggestedCompanies",
    "fundingCompanies",
    "companyDetailsList",
    "purchaseNegociation",
    "indexPropertyMenu"
  ]

  static values = {
    mapboxApiKey: String,
    url: String,
    apiKey: String,
    loanRate: Number,
    repaymentPeriod: Number,
    contributionAmount: Number,
    language: String,
    isUserLoggedIn: Boolean,
    isInventory: Boolean,
    isBuy: Boolean,
    isRent: Boolean,
    isInventoryCompany: Boolean,
    userPropertyIds: Array,
    userCompanyPropertyIds: Array,
    userCompanyPropertyIdsJoin: Array,
    propertyId: Number,
  }

  startY = 0;
  startPos = 0;
  isMapOpen = false;

  connect() {
    // Configuration NProgress (optionnel)
    NProgress.configure({ showSpinner: false });

    this.openModalWithImages = this.openModalWithImages.bind(this);
    this.closeModalWithImages = this.closeModalWithImages.bind(this);

    const returnToAfterLogin = sessionStorage.getItem('returnToAfterLogin');
    if (returnToAfterLogin) {
      window.location.href = returnToAfterLogin;
      sessionStorage.removeItem('returnToAfterLogin'); // Nettoyer après la redirection
    }

    if (!this.isUserLoggedInValue) {
      this.removeModalFromURL();
    }

    let filters = [];
    let filtersString = '';

    if (this.isInventoryValue) {
      // Si on est sur la page de l'inventaire
      if (this.userPropertyIdsValue.length > 0) {
        filters = this.userPropertyIdsValue.map(id => `id = ${id}`);
        filtersString = filters.join(' OR ');
      } else {
        // Si userPropertyIdsValue est vide, ne pas montrer de propriétés
        filtersString = "id = 0"; // Aucun id n'est égal à 0, donc aucun résultat
      }
    } else if (this.isInventoryCompanyValue) {
      // Si on est sur la page de l'inventaire
      if (this.userCompanyPropertyIdsValue.length > 0) {
        filters = this.userCompanyPropertyIdsValue.map(id => `id = ${id}`);
        filtersString = filters.join(' OR ');
      } else {
        // Si userCompanyPropertyIdsValue est vide, ne pas montrer de propriétés
        filtersString = "id = 0"; // Aucun id n'est égal à 0, donc aucun résultat
      }
    } else {
      // Sinon, on exclut les propriétés de l'utilisateur
      if (this.userCompanyPropertyIdsJoinValue.length > 0) {
        filters = this.userCompanyPropertyIdsJoinValue.map(id => `NOT id = ${id}`);
        filtersString = filters.join(' AND ');

        // Ajout des filtres pour exclure les propriétés non prêtes à être mises en ligne
        if (filtersString) {
          filtersString += ' AND ';
        }
        filtersString += 'ready_to_go_online = true AND on_line = true';

        // Ajout des filtres pour le type de transaction
        if (this.isBuyValue) {
          if (filtersString) {
            filtersString += ' AND ';
          }
          filtersString += 'object = "sell"';
        }

        if (this.isRentValue) {
          if (filtersString) {
            filtersString += ' AND ';
          }
          filtersString += 'object = "rent"';
        }
      } else {
        filtersString = ''; // Aucune exclusion si userCompanyPropertyIdsJoinValue est vide

        // Ajout des filtres pour exclure les propriétés non prêtes à être mises en ligne
        if (filtersString) {
          filtersString += ' AND ';
        }
        filtersString += 'ready_to_go_online = true AND on_line = true';

        // Ajout des filtres pour le type de transaction
        if (this.isBuyValue) {
          if (filtersString) {
            filtersString += ' AND ';
          }
          filtersString += 'object = "sell"';
        }

        if (this.isRentValue) {
          if (filtersString) {
            filtersString += ' AND ';
          }
          filtersString += 'object = "rent"';
        }
      }
    }

    // const scrollPosition = sessionStorage.getItem("scrollPropertyPosition");
    // if (scrollPosition) {
    //   window.scrollTo(0, parseInt(scrollPosition));
    // }

    mapboxgl.accessToken = this.mapboxApiKeyValue

    const url = this.urlValue;
    const apiKey = this.apiKeyValue;

    const customGeoSearch = connectGeoSearch(this.renderGeoSearch);
    const customStats = connectStats(this.renderStats);
    const customTitleStats = connectStats(this.renderTitleStats);
    const searchClient = instantMeiliSearch(url, apiKey);
    this.meiliSearchClient = searchClient;

    const search = instantsearch({
      indexName: 'Property',
      searchClient,
      routing: {
        router: history({
          cleanUrlOnDispose: false,
        }),
      },
      future: {
        preserveSharedStateOnUnmount: true,
      },
    });

    search.addWidgets([

      configure({
        hitsPerPage: 25,
        filters: filtersString,
      }),

      customStats({
        container: document.querySelector('#stats'),
      }),

      customTitleStats({
        container: document.querySelector('#title-stats'),
      }),

      clearRefinements({
        container: '#clear-refinements',
        templates: {
          resetLabel: ({ hasRefinements }) => {
            return `<span>${hasRefinements ? i18n.t('meilisearch.delete_filter') : i18n.t('meilisearch.no_filter_selected')}</span>`;
          },
        },
      }),

      refinementList({
        container: '#type-property-refinement-list',
        attribute: 'type_property',
        templates: {
          item: (item, { html }) => {
            // Utilisez ici une fonction normale ou une fonction fléchée sans `html`` pour construire votre template
            const isChecked = item.isRefined ? 'checked' : '';
            const label = i18n.t(`activerecord.attributes.property.type_properties.${item.value}`);
            const count = item.count;
            // Construisez votre HTML sous forme de chaîne de caractères
            return `
              <label class="ais-RefinementList-label">
                <input class="ais-RefinementList-checkbox" type="checkbox" value="${item.value}" ${isChecked}>
                <span class="ais-RefinementList-labelText">${label}</span>
                <span class="ais-RefinementList-count">${count}</span>
              </label>
            `;
          },
        },
      }),

      rangeInput({
        container: '#total-selling-price-range-input',
        attribute: 'condition.total_selling_price',
        templates: {
          separatorText: () => i18n.t('meilisearch.to'),
          submitText: () => i18n.t('meilisearch.validate'),
        },
      }),

      rangeInput({
        container: '#living-sapce-area-range-input',
        attribute: 'description.living_space_area',
        templates: {
          separatorText: () => i18n.t('meilisearch.to'),
          submitText: () => i18n.t('meilisearch.validate'),
        },
      }),

      refinementList({
        container: '#main-room-refinement-list',
        attribute: 'description.main_room_number',
        limit: 5,
        showMore: true,
        sortBy: ['main_room_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more'),
        },
      }),

      refinementList({
        container: '#bedroom-refinement-list',
        attribute: 'description.bedroom_number',
        limit: 3,
        showMore: true,
        sortBy: ['bedroom_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more'),
        },
      }),

      refinementList({
        container: '#bathroom-refinement-list',
        attribute: 'description.bathroom_number',
        limit: 3,
        showMore: true,
        sortBy: ['bathroom_number:asc'],
        templates: {
          showMoreText: (data) => data.isShowingMore ? i18n.t('meilisearch.see_less') : i18n.t('meilisearch.see_more'),
        },
      }),

      customGeoSearch(items => renderGeoSearch(items)),

      hits({
        container: ".cards-properties",
        templates: {
          item: (item) => this.renderProperty(item), // Assurez-vous que renderProperty utilise `html` si elle génère du HTML dynamique
          empty: () => `
            <div>
              <p class="border rounded border-danger text-danger p-3 my-3">${i18n.t('meilisearch.no_result')}</p>
              ${this.isInventoryValue || this.isInventoryCompanyValue ? `
                <div class="text-end">
                  <button type="button" class="btn btn-primary" onclick="window.location.href='/properties/new'">
                    ${i18n.t('dashboards.inventory.add_new_real_estate_label')}
                  </button>
                </div>
              ` : ''}
                <div class="text-center mt-4">
                  <img src="https://res.cloudinary.com/dqicgjooj/image/upload/v1706777214/Configuration/eprnzg3w0h4rjy3el6wv.png" class="w-100" alt="Add New Property">
                </div>
            </div>
          `,
        }
      }),

      pagination({
        container: '#pagination',
      })
    ]);

    search.start();
    search.on('render', () => {
      // const hits = document.querySelector('.ais-Hits')
      // if (hits && isMobileOrTablet) {
      //   const height = hits.clientHeight;
      //   const flipCard = document.querySelector('.flip-card');
      //   flipCard.style.height = flipCard.classList.contains("is-flipped") ? "0px" : `${height}px`;
      // }
      this.restoreMeta("meta.titles.properties_index", "meta.descriptions.properties_index", "meta.keywords.properties_index"); // Initialiser les meta
    });

    this.search = search;

    this.initialUrl = window.location.href;
    this.handlePopState = this.handlePopState.bind(this);
    window.addEventListener('popstate', this.handlePopState);

    this.readURLAndInitializeInputs(); // Initialiser les inputs avec les valeurs de l'URL

    // Écouter l'événement personnalisé 'openPropertyDetails'
    // this.element.addEventListener('openPropertyDetails', event => this.openDetails(event));
  }

  disconnect() {
    // Retirer le gestionnaire popstate pour éviter des fuites de mémoire
    window.removeEventListener('popstate', this.handlePopState.bind(this));
  }

  removeModalFromURL() {
    const url = new URL(window.location);
    url.searchParams.delete('modalFunding');
    url.searchParams.delete('modalPurchaseNegociation');
    window.history.replaceState({}, '', url);
  }

  async restoreMeta(titleKey, descriptionKey, keywordsKey, propertyId) {
    let nbHits = 0;
    let nbApartments = 0;
    let nbHouses = 0;
    let nbLands = 0;
    let minPrice = 0;
    let maxPrice = 0;
    let minLivingSpaceArea = 0;
    let maxLivingSpaceArea = 0;
    let minMainRoomNumber = 0;
    let maxMainRoomNumber = 0;
    let minBedroomNumber = 0;
    let maxBedroomNumber = 0;
    let minBathroomNumber = 0;
    let maxBathroomNumber = 0;
    let typeProperty = "";
    let zipcode = "";
    let city = "";
    let price = 0;
    let livingSpaceArea = 0;
    let mainRoomNumber = 0;
    let bedroomNumber = 0;
    let bathroomNumber = 0;

    if (propertyId) {
      try {
        const property = await this.findPropertyById(propertyId);
        if (property) {
          // Exemple d'utilisation de la propriété trouvée
          typeProperty = this.#formatTypeAndObject(property);
          zipcode = property.zipcode;
          city = property.city;
          price = property.condition.total_selling_price;
          if (property.description.living_space_area) {
            livingSpaceArea = property.description.living_space_area;
          } else if (property.description.plot_area) {
            livingSpaceArea = property.description.plot_area;
          }
          mainRoomNumber = property.description.main_room_number;
          bedroomNumber = property.description.bedroom_number;
          bathroomNumber = property.description.bathroom_number;

          if (property.photos && property.photos.length > 0) {
            let imageUrl = property.photos[0]; // Assurez-vous que l'URL est complète
            let metaOgImage = document.querySelector("meta[property='og:image']");
            if (metaOgImage) {
              metaOgImage.setAttribute("content", imageUrl);
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la propriété :", error);
      }
    } else {
      let imageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1706777207/Configuration/g4ltxpss6vyki0fhjtoz.png"; // Assurez-vous que l'URL est complète
      let metaOgImage = document.querySelector("meta[property='og:image']");
      if (metaOgImage) {
        metaOgImage.setAttribute("content", imageUrl);
      }
    }

    if (this.search && this.search.helper && this.search.helper.lastResults) {
      // Vérifiez si lastResults est disponible avant d'y accéder
      const searchResults = this.search.helper.lastResults;
      nbHits = searchResults ? searchResults.nbHits : 0;

      // Récupération des données de facette directement depuis les résultats bruts
      let facets = searchResults.disjunctiveFacets || [];

      // Trouver les données de facette pour 'type_property'
      const typePropertyFacet = facets.find(facet => facet.name === 'type_property');

      if (typePropertyFacet && typePropertyFacet.data) {
        nbApartments = typePropertyFacet.data.apartment || 0;
        nbHouses = typePropertyFacet.data.house || 0;
        nbLands = typePropertyFacet.data.land || 0;
      }

      // Trouver les données de facette pour 'total_selling_price'
      const totalSellingPriceFacet = facets.find(facet => facet.name === 'condition.total_selling_price');
      if (totalSellingPriceFacet && totalSellingPriceFacet.data) {
        minPrice = totalSellingPriceFacet.stats.min || 0;
        maxPrice = totalSellingPriceFacet.stats.max || 0;
      }

      // Trouver les données de facette pour 'living_space_area'
      const livingSpaceAreaFacet = facets.find(facet => facet.name === 'description.living_space_area');

      if (livingSpaceAreaFacet && livingSpaceAreaFacet.data) {
        minLivingSpaceArea = livingSpaceAreaFacet.stats.min || 0;
        maxLivingSpaceArea = livingSpaceAreaFacet.stats.max || 0;
      }

      // Trouver les données de facette pour 'main_room_number'
      const mainRoomNumberFacet = facets.find(facet => facet.name === 'description.main_room_number');

      if (mainRoomNumberFacet && mainRoomNumberFacet.data) {
        minMainRoomNumber = mainRoomNumberFacet.stats.min || 0;
        maxMainRoomNumber = mainRoomNumberFacet.stats.max || 0;
      }

      // Trouver les données de facette pour 'bedroom_number'
      const bedroomNumberFacet = facets.find(facet => facet.name === 'description.bedroom_number');

      if (bedroomNumberFacet && bedroomNumberFacet.data) {
        minBedroomNumber = bedroomNumberFacet.stats.min || 0;
        maxBedroomNumber = bedroomNumberFacet.stats.max || 0;
      }

      // Trouver les données de facette pour 'bathroom_number'
      const bathroomNumberFacet = facets.find(facet => facet.name === '"description.bathroom_number"');

      if (bathroomNumberFacet && bathroomNumberFacet.data) {
        minBathroomNumber = bathroomNumberFacet.stats.min || 0;
        maxBathroomNumber = bathroomNumberFacet.stats.max || 0;
      }
    }

    if (titleKey) {
      // Utiliser `nbHits` pour afficher le nombre de résultats dans le titre si fourni
      const title = nbHits ?
        i18n.t(
          titleKey, {
            count: nbHits,
            nb_apartments: nbApartments,
            nb_houses: nbHouses,
            nb_lands: nbLands,
            type_property: typeProperty,
            city: city,
            zipcode: zipcode,
            price: price
          }
        )
      :
      i18n.t(titleKey);

      document.title = title;
      const metaOgTitle = document.querySelector("meta[property='og:title']");
      if (metaOgTitle) {
        metaOgTitle.setAttribute("content", title);
      }
    }

    if (descriptionKey) {
      const description = nbHits ?
        i18n.t(
          descriptionKey, {
            count: nbHits,
            nb_apartments: nbApartments,
            nb_houses: nbHouses,
            nb_lands: nbLands,
            min_price: minPrice,
            max_price: maxPrice,
            min_living_space_area: minLivingSpaceArea,
            max_living_space_area: maxLivingSpaceArea,
            min_main_room_number: minMainRoomNumber,
            max_main_room_number: maxMainRoomNumber,
            min_bedroom_number: minBedroomNumber,
            max_bedroom_number: maxBedroomNumber,
            min_bathroom_number: minBathroomNumber,
            max_bathroom_number: maxBathroomNumber,
            type_property: typeProperty,
            city: city,
            zipcode: zipcode,
            price: price,
            living_space_area: livingSpaceArea,
            main_room_number: mainRoomNumber,
            bedroom_number: bedroomNumber,
            bathroom_number: bathroomNumber,
          }
        )
        :
        i18n.t(titleKey);

      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
      const metaOgDescription = document.querySelector("meta[property='og:description']");
      if (metaOgDescription) {
        metaOgDescription.setAttribute("content", description);
      }

      // Lire l'URL actuelle de la page
      const currentUrl = window.location.href;

      // Sélectionner la balise meta 'og:url' et mettre à jour son contenu
      const metaOgUrl = document.querySelector("meta[property='og:url']");
      if (metaOgUrl) {
        metaOgUrl.setAttribute("content", currentUrl);
      }
    }

    if (keywordsKey) {
      const keywords = i18n.t(keywordsKey)
      const metaKeywords = document.querySelector("meta[name='keywords']");
      if (metaKeywords) {
        metaKeywords.setAttribute("content", keywords);
      }
    }
  }

  async restoreMetaCompany(titleKey, descriptionKey, keywordsKey, companyId) {
    let nbHits = 0;
    let name = "";
    let address = "";
    let allTypeService = "";
    let areaServed = Array.isArray(this.areaServedQueryArrayValue) ? this.areaServedQueryArrayValue.join(',') : '';
    let areaServedFormated = this.formatDescription(areaServed);
    let typeService = "";

    if (companyId) {
      try {
        const company = await this.findCompanyById(companyId);
        if (company) {
          name = company.name
          address = this.formatDescription(company.address);
          allTypeService = this.formatDescription(company.type_service);
          typeService = this.formatDescription(company.type_service);
          if (company.company_profile_document_url) {
            let imageUrl = company.company_profile_document_url; // Assurez-vous que l'URL est complète
            let metaOgImage = document.querySelector("meta[property='og:image']");
            if (metaOgImage) {
              metaOgImage.setAttribute("content", imageUrl);
            }
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la propriété :", error);
      }
    } else {
      let imageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1706777207/Configuration/g4ltxpss6vyki0fhjtoz.png"; // Assurez-vous que l'URL est complète
      let metaOgImage = document.querySelector("meta[property='og:image']");
      if (metaOgImage) {
        metaOgImage.setAttribute("content", imageUrl);
      }
    }

    if (this.search && this.search.helper && this.search.helper.lastResults) {
      // Vérifiez si lastResults est disponible avant d'y accéder
      const searchResults = this.search.helper.lastResults;
      nbHits = searchResults ? searchResults.nbHits : 0;
    }

    if (titleKey) {
      // Utiliser `nbHits` pour afficher le nombre de résultats dans le titre si fourni
      const title = nbHits > 0 ? i18n.t(titleKey, { count: nbHits, name: name, type_service: typeService, area_served: areaServedFormated, address: address}) : i18n.t(titleKey, { count: nbHits });
      document.title = title;
      const metaOgTitle = document.querySelector("meta[property='og:title']");
      if (metaOgTitle) {
        metaOgTitle.setAttribute("content", title);
      }
    }

    if (descriptionKey) {
      const description = i18n.t(descriptionKey, { name: name, all_type_service: allTypeService, type_service: typeService, area_served: areaServedFormated, address: address });

      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", description);
      }
      const metaOgDescription = document.querySelector("meta[property='og:description']");
      if (metaOgDescription) {
        metaOgDescription.setAttribute("content", description);
      }

      // Lire l'URL actuelle de la page
      const currentUrl = window.location.href;

      // Sélectionner la balise meta 'og:url' et mettre à jour son contenu
      const metaOgUrl = document.querySelector("meta[property='og:url']");
      if (metaOgUrl) {
        metaOgUrl.setAttribute("content", currentUrl);
      }
    }

    if (keywordsKey) {
      const keywords = i18n.t(keywordsKey)
      const metaKeywords = document.querySelector("meta[name='keywords']");
      if (metaKeywords) {
        metaKeywords.setAttribute("content", keywords);
      }
    }
  }

  formatDescription(description) {
    let formattedDescription = ""; // Déclaration locale pour stocker le résultat formaté
    if (Array.isArray(description)) {
      // Si c'est un tableau, appliquez directement le traitement
      formattedDescription = description
          .map(part => part.trim())
          .filter(part => part)
          .map(part => part.charAt(0).toUpperCase() + part.slice(1))
          .join(', ');
    } else if (typeof description === 'string') {
      // Si c'est une chaîne, utilisez split puis continuez comme avant
      formattedDescription = description
          .split(',')
          .map(part => part.trim())
          .filter(part => part)
          .map(part => part.charAt(0).toUpperCase() + part.slice(1))
          .join(', ');
    } else {
      // Gérer d'autres types ou des valeurs inattendues
      console.log('Unexpected type for description:', description);
      formattedDescription = '';  // ou une gestion d'erreur appropriée
    }
    return formattedDescription;
  }

  // startLoading() {
  //   document.getElementById('loading-overlay').classList.remove('hidden');
  // }

  // stopLoading() {
  //   document.getElementById('loading-overlay').classList.add('hidden');
  // }

  setupTooltip() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    if (tooltipTriggerList.length) {
      tooltipTriggerList.forEach(tooltipTriggerEl => {
        let oldTooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
        if (oldTooltip) {
          oldTooltip.dispose();
        }

        let newTooltip = new bootstrap.Tooltip(tooltipTriggerEl, {
          html: true,
          customClass: tooltipTriggerEl.getAttribute('data-bs-custom-class') || ''
        });

        const closeTooltipOnScroll = () => {
          newTooltip.hide();
          window.removeEventListener('scroll', closeTooltipOnScroll);
          window.removeEventListener('touchmove', closeTooltipOnScroll);
        };

        tooltipTriggerEl.addEventListener('shown.bs.tooltip', () => {
          window.addEventListener('scroll', closeTooltipOnScroll);
          window.addEventListener('touchmove', closeTooltipOnScroll, { passive: true });
        });

        tooltipTriggerEl.addEventListener('hidden.bs.tooltip', () => {
          window.removeEventListener('scroll', closeTooltipOnScroll);
          window.removeEventListener('touchmove', closeTooltipOnScroll);
        });
      });
    }
  }

  preventDefaultTouch(event) {
    let isInImagesContainer = event.target.closest('.images-container');
    if (!isInImagesContainer) {
      event.preventDefault();
    }
  }

  hiddenVisibiltyTurboRender() {
    document.body.classList.add('no-scroll');
    this.topNavbar = document.querySelector('.top-navbar')
    this.buttomNavbar = document.querySelector('.bottomNavbar')
    this.notificationContainer = document.querySelector('.notification-container')

    if (this.hasFlipCardTarget) {
      this.flipCardTarget.classList.add('no-scroll');
      this.flipCardTarget.classList.add('hidden-no-scroll');
      this.flipCardTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }

    if (this.hasIndexPropertyMenuTarget) {
      this.indexPropertyMenuTarget.classList.add('no-scroll');
      this.indexPropertyMenuTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }

    if (this.topNavbar) {
      this.topNavbar.classList.add('hidden-no-scroll');
      this.topNavbar.classList.add('no-scroll');
      this.topNavbar.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }

    if (this.buttomNavbar) {
      this.buttomNavbar.classList.add('hidden-no-scroll');
      this.buttomNavbar.classList.add('no-scroll');
      this.buttomNavbar.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }

    if (this.notificationContainer) {
      this.notificationContainer.classList.add('d-none');
      this.notificationContainer.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }
  }

  showVisibiltyTurboRender() {
    this.topNavbar = document.querySelector('.top-navbar')
    this.buttomNavbar = document.querySelector('.bottomNavbar')
    this.notificationContainer = document.querySelector('.notification-container')

    if (!this.isMapOpen &&
        !this.fundingCompaniesTarget.classList.contains("active") &&
        !this.suggestedCompaniesTarget.classList.contains("active") &&
        !this.purchaseNegociationTarget.classList.contains("active") &&
        !this.companyDetailsListTarget.classList.contains("active")) {

      // Afficher les éléments lorsque la modal se ferme
      document.body.classList.remove('no-scroll');

      if (this.hasFlipCardTarget) {
        this.flipCardTarget.classList.remove('no-scroll');
        this.flipCardTarget.classList.remove('hidden-no-scroll');
        this.flipCardTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      if (this.hasIndexPropertyMenuTarget) {
        this.indexPropertyMenuTarget.classList.remove('no-scroll');
        this.indexPropertyMenuTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      if (this.topNavbar) {
        this.topNavbar.classList.remove('hidden-no-scroll');
        this.topNavbar.classList.remove('no-scroll');
        this.topNavbar.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      if (this.buttomNavbar) {
        this.buttomNavbar.classList.remove('hidden-no-scroll');
        this.buttomNavbar.classList.remove('no-scroll');
        this.buttomNavbar.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      if (this.notificationContainer) {
        this.notificationContainer.classList.remove('d-none');
        this.notificationContainer.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

    }
    else if (this.companyDetailsListTarget.classList.contains("active")) {
      this.fundingCompaniesTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }
    else if (this.suggestedCompaniesTarget.classList.contains("active")) {
      this.propertyDetailsListTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }
    else if (this.fundingCompaniesTarget.classList.contains("active")) {
      this.propertyDetailsListTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }
    else if (this.purchaseNegociationTarget.classList.contains("active")) {
      this.propertyDetailsListTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
    }
  }

  // Création et initialisation du Swiper
  openDetailsInitializeSwiper(swiperContainer, propertyId) {
    const carousel = this.propertyDetailsListTarget.querySelector(`#property-show-carousel-picture`);
    // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
    const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;
    // Suppression du contenu existant du carrousel
    carousel.innerHTML = '';

    // Création d'une copie du carrousel existant
    const clonedCarousel = swiperContainer.cloneNode(true);
    const newCarouselId = `carouselPicture-new-visit-property-${propertyId}`;
    clonedCarousel.setAttribute('id', newCarouselId);
    clonedCarousel.setAttribute('data-swiper-property-index-value', false);
    clonedCarousel.removeAttribute('data-controller');
    carousel.appendChild(clonedCarousel);

    const slides = clonedCarousel.querySelectorAll('.swiper-slide');
    slides.forEach((slide, index) => {
      // Pour chaque image à l'intérieur du slide, ajoutez l'attribut data-action qui appelle l'action openModalWithImages
      const images = slide.querySelectorAll('img'); // Assumant que vos images sont directement à l'intérieur des slides
      images.forEach((img) => {
        img.setAttribute('data-action', 'click->meilisearch-property#openModalWithImages');
        // Ajouter un identifiant ou un indice de slide si nécessaire pour identifier l'image dans l'action
        img.dataset.slideIndex = index; // Optionnel, si vous avez besoin de passer l'indice du slide à l'action
      });
    });

    // Compter le nombre de slides dans le carrousel cloné
    const hasManyImages = slides.length > 5;

    // Réinitialiser le plugin Swiper sur le clone
    this.swiper = new Swiper(clonedCarousel.querySelector('.swiper'), {
      loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
      spaceBetween: 10, // Espace entre les slides
      pagination: {
        el: ".swiper-pagination", // Élément pour la pagination
        type: 'fraction', // Type de pagination
      },
      navigation: {
        nextEl: ".swiper-button-next", // Bouton suivant
        prevEl: ".swiper-button-prev", // Bouton précédent
      },
      zoom: {
        maxRatio: 3, // Maximum zoom
      },
      initialSlide: initialIndex,
    });

    const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
    if (originalSwiperController) {
      originalSwiperController.synchronizeWith(this.swiper);
    }
  }

  openQuoteSuggestedCompaniesInitializeSwiper(swiperContainer, propertyId) {
    const carousel = this.suggestedCompaniesTarget.querySelector(`#suggested-carousel-picture`);
    // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
    const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;
    // Suppression du contenu existant du carrousel
    carousel.innerHTML = '';

    // Création d'une copie du carrousel existant
    const clonedCarousel = swiperContainer.cloneNode(true);
    const newCarouselId = `carouselSuggested-property-${propertyId}`;
    clonedCarousel.setAttribute('id', newCarouselId);
    clonedCarousel.setAttribute('data-swiper-property-index-value', false);
    clonedCarousel.removeAttribute('data-controller');
    carousel.appendChild(clonedCarousel);

    const slides = clonedCarousel.querySelectorAll('.swiper-slide');
    slides.forEach((slide, index) => {
      // Pour chaque image à l'intérieur du slide, ajoutez l'attribut data-action qui appelle l'action openModalWithImages
      const images = slide.querySelectorAll('img'); // Assumant que vos images sont directement à l'intérieur des slides
      images.forEach((img) => {
        img.setAttribute('data-action', 'click->meilisearch-property#openModalWithImages');
        // Ajouter un identifiant ou un indice de slide si nécessaire pour identifier l'image dans l'action
        img.dataset.slideIndex = index; // Optionnel, si vous avez besoin de passer l'indice du slide à l'action
      });
    });

    // Compter le nombre de slides dans le carrousel cloné
    const hasManyImages = slides.length > 5;

    // Réinitialiser le plugin Swiper sur le clone
    const swiperInstance = new Swiper(clonedCarousel.querySelector('.swiper'), {
      loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
      spaceBetween: 10, // Espace entre les slides
      pagination: {
        el: ".swiper-pagination", // Élément pour la pagination
        type: 'fraction', // Type de pagination
      },
      navigation: {
        nextEl: ".swiper-button-next", // Bouton suivant
        prevEl: ".swiper-button-prev", // Bouton précédent
      },
      zoom: {
        maxRatio: 3, // Maximum zoom
      },
      initialSlide: initialIndex,
    });

    const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
    if (originalSwiperController) {
      originalSwiperController.synchronizeWith(swiperInstance);
    }
  }

  openQuoteFundingCompaniesInitializeSwiper(swiperContainer, propertyId) {
    const carousel = this.fundingCompaniesTarget.querySelector(`#funding-carousel-picture`);
    // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
    const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;
    // Suppression du contenu existant du carrousel
    carousel.innerHTML = '';

    // Création d'une copie du carrousel existant
    const clonedCarousel = swiperContainer.cloneNode(true);
    const newCarouselId = `carouselFunding-property-${propertyId}`;
    clonedCarousel.setAttribute('id', newCarouselId);
    clonedCarousel.setAttribute('data-swiper-property-index-value', false);
    clonedCarousel.removeAttribute('data-controller');
    carousel.appendChild(clonedCarousel);

    const slides = clonedCarousel.querySelectorAll('.swiper-slide');
    slides.forEach((slide, index) => {
      // Pour chaque image à l'intérieur du slide, ajoutez l'attribut data-action qui appelle l'action openModalWithImages
      const images = slide.querySelectorAll('img'); // Assumant que vos images sont directement à l'intérieur des slides
      images.forEach((img) => {
        img.setAttribute('data-action', 'click->meilisearch-property#openModalWithImages');
        // Ajouter un identifiant ou un indice de slide si nécessaire pour identifier l'image dans l'action
        img.dataset.slideIndex = index; // Optionnel, si vous avez besoin de passer l'indice du slide à l'action
      });
    });

    // Compter le nombre de slides dans le carrousel cloné
    const hasManyImages = slides.length > 5;

    // Réinitialiser le plugin Swiper sur le clone
    const swiperInstance = new Swiper(clonedCarousel.querySelector('.swiper'), {
      loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
      spaceBetween: 10, // Espace entre les slides
      pagination: {
        el: ".swiper-pagination", // Élément pour la pagination
        type: 'fraction', // Type de pagination
      },
      navigation: {
        nextEl: ".swiper-button-next", // Bouton suivant
        prevEl: ".swiper-button-prev", // Bouton précédent
      },
      zoom: {
        maxRatio: 3, // Maximum zoom
      },
      initialSlide: initialIndex,
    });

    const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
    if (originalSwiperController) {
      originalSwiperController.synchronizeWith(swiperInstance);
    }
  }

  openPurchaseNegociationInitializeSwiper(swiperContainer, propertyId) {
    const carousel = this.purchaseNegociationTarget.querySelector(`#purchase-negociation-new-carousel-picture`);
    // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
    const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;
    // Suppression du contenu existant du carrousel
    carousel.innerHTML = '';

    // Création d'une copie du carrousel existant
    const clonedCarousel = swiperContainer.cloneNode(true);
    const newCarouselId = `carouselPicture-new-purchase-negociation-property-${propertyId}`;
    clonedCarousel.setAttribute('id', newCarouselId);
    clonedCarousel.setAttribute('data-swiper-property-index-value', false);
    clonedCarousel.removeAttribute('data-controller');
    carousel.appendChild(clonedCarousel);

    const slides = clonedCarousel.querySelectorAll('.swiper-slide');
    slides.forEach((slide, index) => {
      // Pour chaque image à l'intérieur du slide, ajoutez l'attribut data-action qui appelle l'action openModalWithImages
      const images = slide.querySelectorAll('img'); // Assumant que vos images sont directement à l'intérieur des slides
      images.forEach((img) => {
        img.setAttribute('data-action', 'click->meilisearch-property#openModalWithImages');
        // Ajouter un identifiant ou un indice de slide si nécessaire pour identifier l'image dans l'action
        img.dataset.slideIndex = index; // Optionnel, si vous avez besoin de passer l'indice du slide à l'action
      });
    });

    // Compter le nombre de slides dans le carrousel cloné
    const hasManyImages = slides.length > 5;

    // Réinitialiser le plugin Swiper sur le clone
    const swiperInstance = new Swiper(clonedCarousel.querySelector('.swiper'), {
      loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
      spaceBetween: 10, // Espace entre les slides
      pagination: {
        el: ".swiper-pagination", // Élément pour la pagination
        type: 'fraction', // Type de pagination
      },
      navigation: {
        nextEl: ".swiper-button-next", // Bouton suivant
        prevEl: ".swiper-button-prev", // Bouton précédent
      },
      zoom: {
        maxRatio: 3, // Maximum zoom
      },
      initialSlide: initialIndex,
    });

    const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
    if (originalSwiperController) {
      originalSwiperController.synchronizeWith(swiperInstance);
    }
  }

  // Méthode pour lire l'URL et initialiser les inputs
  async readURLAndInitializeInputs() {
    const urlParams = new URLSearchParams(window.location.search);
    const modalPropertyDetails = urlParams.get('modalPropertyDetails');
    const modalSuggested = urlParams.get('modalSuggested');
    const modalCompany = urlParams.get('modalCompany');
    const modalFunding = urlParams.get('modalFunding');
    const modalPurchaseNegociation = urlParams.get('modalPurchaseNegociation');

    if (modalPropertyDetails) {
      await this.observerOpenDetailsById(modalPropertyDetails); // Attend que le rendu soit complet
      if (modalSuggested) {
        this.openQuoteSuggestedCompaniesById(modalSuggested); // Exécuté une fois que openDetailsById est terminé
        if (modalCompany) {
          this.openCompanyDetailsById(modalCompany); // Exécuté une fois que openDetailsById est terminé
        }
      }
      if (modalFunding) {
        this.openQuoteFundingCompaniesById(modalFunding); // Exécuté une fois que openDetailsById est terminé
      }
      if (modalPurchaseNegociation) {
        this.openPurchaseNegociationById(modalPurchaseNegociation); // Exécuté une fois que openDetailsById est terminé
      }
    }
  }

  // Méthode pour rechercher une propriété par ID
  async findPropertyById(propertyId) {
    if (!this.meiliSearchClient) {
        console.error("Le client MeiliSearch n'est pas initialisé.");
        return;
    }

    try {
        // L'appel à MeiliSearch renvoie une promesse qui résout dans un objet contenant un tableau de résultats
        const response = await this.meiliSearchClient.search([{ indexName: 'Property', params: { filters: `id=${propertyId}` } }]);

        // Corrigez l'accès aux hits ici
        const hits = response.results[0]?.hits;

        if (hits && hits.length > 0) {
            return hits[0]; // Si 'hits' est présent et contient au moins un résultat, retourne le premier hit
        } else {
            console.log("Aucune propriété trouvée avec cet ID.");
            return null; // Gérez le cas où aucune propriété n'est trouvée
        }
    } catch (error) {
        console.error("Erreur lors de la recherche dans MeiliSearch :", error);
        return null;
    }
  }

  // Méthode pour rechercher une company par ID
  async findCompanyById(companyId) {
    if (!this.meiliSearchClient) {
        console.error("Le client MeiliSearch n'est pas initialisé.");
        return;
    }

    try {
        // L'appel à MeiliSearch renvoie une promesse qui résout dans un objet contenant un tableau de résultats
        const response = await this.meiliSearchClient.search([{ indexName: 'Company', params: { filters: `id=${companyId}` } }]);

        // Corrigez l'accès aux hits ici
        const hits = response.results[0]?.hits;

        if (hits && hits.length > 0) {
            return hits[0]; // Si 'hits' est présent et contient au moins un résultat, retourne le premier hit
        } else {
            console.log("Aucune propriété trouvée avec cet ID.");
            return null; // Gérez le cas où aucune propriété n'est trouvée
        }
    } catch (error) {
        console.error("Erreur lors de la recherche dans MeiliSearch :", error);
        return null;
    }
  }

  // Met à jour la page avec bouton retour du navigateur
  async handlePopState(event) {
    // Obtenir les paramètres de l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const modalPropertyDetails = urlParams.get('modalPropertyDetails');
    const modalCompanyDetails = urlParams.get('modalCompany');
    const modalPurchaseNegociation = urlParams.get('modalPurchaseNegociation');
    const quoteFundingCompanies = urlParams.get('modalFunding');
    const modalSuggested = urlParams.get('modalSuggested');

    let newUrl = new URL(window.location);
    const params = newUrl.searchParams;

    // Ouvrir les modales appropriées en fonction de l'URL
    if (modalCompanyDetails) {
      if (quoteFundingCompanies) {
        await this.openCompanyDetailsById(modalCompanyDetails);
        this.deactivateSection(this.fundingCompaniesTarget, 'modalFunding', params);
        this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
      } else if (modalSuggested) {
        await this.openCompanyDetailsById(modalCompanyDetails);
        this.deactivateSection(this.suggestedCompaniesTarget, 'modalSuggested', params);
        this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
      }
    } else if (modalSuggested) {
      await this.openQuoteSuggestedCompaniesById(modalSuggested);
      this.deactivateSection(this.companyDetailsListTarget, 'modalCompanyDetails', params);
      this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
    } else if (quoteFundingCompanies) {
      await this.openQuoteFundingCompaniesById(quoteFundingCompanies);
      this.deactivateSection(this.companyDetailsListTarget, 'modalCompanyDetails', params);
      this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
    } else if (modalPurchaseNegociation) {
      await this.openPurchaseNegociationById(modalPurchaseNegociation);
      this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
    } else if (modalPropertyDetails) {
      await this.openDetailsById(modalPropertyDetails);
      this.deactivateSection(this.companyDetailsListTarget, 'modalCompanyDetails', params);
      this.deactivateSection(this.fundingCompaniesTarget, 'modalFunding', params);
      this.deactivateSection(this.purchaseNegociationTarget, 'modalPurchaseNegociation', params);
      this.deactivateSection(this.suggestedCompaniesTarget, 'modalSuggested', params);
    } else {
      document.body.classList.remove('no-scroll');

      if (this.hasFlipCardTarget) {
        this.flipCardTarget.classList.remove('no-scroll');
        this.flipCardTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      if (this.hasIndexPropertyMenuTarget) {
        this.indexPropertyMenuTarget.classList.remove('no-scroll');
        this.indexPropertyMenuTarget.removeEventListener('touchmove', this.preventDefaultTouch, { passive: false });
      }

      this.deactivateSection(this.companyDetailsListTarget, 'modalCompanyDetails', params);
      this.deactivateSection(this.fundingCompaniesTarget, 'modalFunding', params);
      this.deactivateSection(this.purchaseNegociationTarget, 'modalPurchaseNegociation', params);
      this.deactivateSection(this.suggestedCompaniesTarget, 'modalSuggested', params);
      this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);
    }
  }

  openMap() {
    this.mapTarget.classList.add("map-container");
    this.mapTarget.style.display = "block";
    this.mapTarget.classList.remove("hidden-no-scroll", 'hidden');
    this.isMapOpen = true; // Mettez à jour l'état lorsque la map est ouverte
    this.hiddenVisibiltyTurboRender();

    requestAnimationFrame(() => {
      if (map) {
        // Ajoutez ici l'écouteur d'événements pour les clics sur la carte
        map.on('click', (e) => {
          if (!e.originalEvent.target.closest('.mapboxgl-marker')) {
            // Si l'utilisateur n'a pas cliqué sur un marqueur, fermez la carte de détails de propriété
            this.closePropertyCard();
          }
        });

        if (markers.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();

          markers.forEach(marker => {
            bounds.extend(marker.getLngLat());
          });
          map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
        }

        // Forcer le redimensionnement après que la carte soit affichée
        map.resize();
      }
    });
  }

  closePropertyCard() {
    // Cachez la carte de détails de propriété
    const propertyDetails = document.getElementById('propertyMobileModal');
    if (propertyDetails) {
      propertyDetails.style.display = 'none';
    }

    // Réajustez la taille de la carte pour occuper toute la page
    const mapContainer = document.getElementById('property-index-map');
    if (mapContainer) {
      mapContainer.style.height = '100vh'; // Ou toute autre logique de dimensionnement que vous préférez
    }

    // Assurez-vous d'appeler map.resize() pour que Mapbox GL JS ajuste la carte à la nouvelle taille du conteneur
    if (map) map.resize();

    let container = document.getElementById('propertyDetailsContent');
    container.innerHTML = ''; // Nettoyage du contenu précédent
  }

  closeMap() {
    this.mapTarget.style.display = "none";
    this.mapTarget.classList.remove("map-container");
    this.isMapOpen = false; // Mettez à jour l'état lorsque la map est fermée
    this.showVisibiltyTurboRender();

    if (this.hasIndexPropertyMenuTarget) {
      this.indexPropertyMenuTarget.classList.remove('hidden-no-scroll', 'hidden');
    }
    if (this.hasFlipCardTarget) {
      this.flipCardTarget.classList.remove('hidden-no-scroll')
    }
    this.closePropertyCard();
  }

  setScrollPosition() {
    const currentScrollY = window.scrollY;
    sessionStorage.setItem("scrollPropertyPosition", currentScrollY);
  }

  scrollToPosition(scrollY) {
    return new Promise(resolve => {
      const onScroll = function () {
        if (window.scrollY === scrollY) {
          window.removeEventListener('scroll', onScroll);
          resolve();
        }
      };

      window.addEventListener('scroll', onScroll);
      window.scrollTo({ top: scrollY, behavior: 'auto' }); // Utilisez 'auto' pour un défilement rapide

      // Résolution immédiate si le défilement n'est pas nécessaire
      if (window.scrollY === scrollY) {
        window.removeEventListener('scroll', onScroll);
        resolve();
      }
    });
  }

  cardHover(event) {
    const propertyId = event.currentTarget.id;
    const propertyObject = event.currentTarget.getAttribute('data-property-object');

    const bienId = `marker-${propertyId}`;
    const selectedMarker = this.defaultMarkerTargets.find(marker => marker.id === bienId);
    const price = selectedMarker.children[0];
    const otherMarkers = this.defaultMarkerTargets.filter(marker => marker.id !== bienId);

    const isSelected = sessionStorage.getItem(bienId);

    if(event.type === 'mouseenter') {
      otherMarkers.forEach(marker => marker.classList.add('d-none'));
      if (propertyObject !== "work") {
        price.classList.remove('d-none');
        isSelected ? selectedMarker.classList.remove('selected-marker') : selectedMarker.classList.remove('default-marker');
        isSelected ? selectedMarker.classList.add('selected-hovered-marker') : selectedMarker.classList.add('hovered-marker');
      }
    } else if (event.type === 'mouseleave') {
      otherMarkers.forEach(marker => marker.classList.remove('d-none'));
      if (propertyObject !== "work") {
        price.classList.add('d-none');
        isSelected ? selectedMarker.classList.remove('selected-hovered-marker') : selectedMarker.classList.remove('hovered-marker');
        isSelected ? selectedMarker.classList.add('selected-marker') : selectedMarker.classList.add('default-marker');
      }
    }
  }

  markerHover(event) {
    const ids = this.cardsTargets.map(card => card.id);
    const lastId = ids.slice(-1);
    const twoLastIds = ids.slice(-2);
    const bienId = event.currentTarget.id.replace('marker-', ''); // Enlever le préfixe pour correspondre à l'ID de la carte
    const selectedMarker = event.currentTarget;
    const price = selectedMarker.children[0];
    const otherMarkers = this.defaultMarkerTargets.filter(marker => marker.id !== `marker-${bienId}`); // Utiliser l'ID complet pour la comparaison
    const card = this.cardsTargets.find(card => card.id === bienId);
    if (!card) return; // Si la carte n'est pas trouvée, arrêter l'exécution pour éviter des erreurs

    const propertyObject = card.getAttribute('data-property-object');
    const isSelected = sessionStorage.getItem(`marker-${bienId}`);

    if (event.type === 'mouseover') {
      otherMarkers.forEach(marker => marker.classList.add('d-none'));
      if (propertyObject !== "work") {
        price.classList.remove('d-none');
        isSelected ? selectedMarker.classList.remove('selected-marker') : selectedMarker.classList.remove('default-marker');
        isSelected ? selectedMarker.classList.add('selected-hovered-marker') : selectedMarker.classList.add('hovered-marker');
        isSelected ? card.classList.add('hovered-selected-card') : card.classList.add('hovered-card');
      }
    } else if (event.type === 'click') {
      // Vérifier si le marker est déjà sélectionné
      if (!isSelected) {
        sessionStorage.setItem(`marker-${bienId}`, 'selected');
      }
      selectedMarker.classList.remove('hovered-marker');
      card.classList.remove('hovered-card')
      selectedMarker.classList.add('selected-hovered-marker');
      card.classList.add('hovered-selected-card')

      // Utiliser scrollIntoView avec condition sur le nombre de cartes
      if (ids.length === 3) {
        card.scrollIntoView({ behavior: "smooth", block: `${lastId.includes(card.id) ? 'end' : 'start'}` });
      } else if (ids.length < 3) {
        card.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        card.scrollIntoView({ behavior: "smooth", block: `${twoLastIds.includes(card.id) ? 'end' : 'start'}` });
      }
    } else if (event.type === 'mouseout') {
      otherMarkers.forEach(marker => marker.classList.remove('d-none'));
      if (propertyObject !== "work") {
        price.classList.add('d-none');
        isSelected ? card.classList.remove('hovered-selected-card') : card.classList.remove('hovered-card');
        selectedMarker.classList.remove('hovered-marker');
        selectedMarker.classList.remove('selected-hovered-marker');
        isSelected ? selectedMarker.classList.add('selected-marker') : selectedMarker.classList.add('default-marker');
      }
    }
}

  renderGeoSearch(renderOptions, isFirstRender) {
    const { items, currentRefinement, refine } = renderOptions;

    if (isFirstRender) {
      map = new mapboxgl.Map({
        container: document.getElementById('property-index-map'),
        style: "mapbox://styles/mapbox/streets-v12"
      });

      // Configuration initiale de la map et des controls
      if(currentRefinement) {
        const southWest = Object.values(currentRefinement.southWest).reverse();
        const northEast = Object.values(currentRefinement.northEast).reverse();
        map.fitBounds([southWest, northEast], { padding: 70, maxZoom: 15, duration: 0 });
      }

      // Add zoom and rotation controls to the map.
      map.addControl(new mapboxgl.NavigationControl());

      if (!document.getElementById("geocoder").hasChildNodes()) {
        const geocoder = new MapboxGeocoder({
          accessToken: mapboxgl.accessToken,
          mapboxgl: mapboxgl,
          placeholder: `${i18n.t('meilisearch.enter_location')}`,
          countries: 'FR', // Limite les recherches à la France
        });

        document.getElementById('geocoder').appendChild(geocoder.onAdd(map));

        // Écouteur d'événements pour l'autocomplétion de Mapbox
        geocoder.on('result', (e) => {
          if (isMobileOrTablet) {
            document.getElementById('geocoder-spinner').style.display = 'block';
          }

          const { result } = e;
          const { geometry } = result;
          const [lng, lat] = geometry.coordinates;
          const desiredZoomLevel = 11; // Ajustez cette valeur selon vos besoins

          // Utilisez jumpTo pour éviter l'animation
          map.jumpTo({ center: [lng, lat], zoom: desiredZoomLevel });

          const bounds = map.getBounds();
          const ne = bounds._ne;
          const sw = bounds._sw;

          // Raffiner les résultats de recherche
          refine({
            northEast: { lat: ne.lat, lng: ne.lng },
            southWest: { lat: sw.lat, lng: sw.lng },
          });

          if (isMobileOrTablet) {
            document.getElementById('geocoder-spinner').style.display = 'none';
          }
        });
      }

      // Supprimez l'ancien gestionnaire d'événements 'moveend' si existant
      map.off('moveend');
    }

    // Supprimer les anciens markers
    markers.forEach(marker => marker.remove());
    markers = [];

    // Créer de nouveaux markers
    markers = items.map((item) => {
      const isSelected = sessionStorage.getItem(`marker-${item.id}`) === 'selected';
      const markerClass = isSelected ? (isMobileOrTablet ? 'selected-mobile-marker' : 'selected-marker') : (isMobileOrTablet ? 'mobile-marker' : 'default-marker');

      const markerDiv = document.createElement("div");
      markerDiv.innerHTML = `
        <div id="marker-${item.id}" data-meilisearch-property-target="defaultMarker" data-action="${isMobileOrTablet ? "click->meilisearch-property#showRenderProperty" : "mouseout->meilisearch-property#markerHover click->meilisearch-property#markerHover mouseover->meilisearch-property#markerHover"}" class="${markerClass}">
          <span class="${isMobileOrTablet ? 'd-block' : 'd-none'}" data-meilisearch-property-target="priceMarker">${item.object === "work" ? " " : Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(item.condition.total_selling_price)}</span>
        </div>
      `;

      const marker = new mapboxgl.Marker(markerDiv)
        .setLngLat([item._geo.lng, item._geo.lat])
        .addTo(map);

      return marker;
    });

    if (markers.length > 0) {
      if (!currentRefinement) {
        const bounds = new mapboxgl.LngLatBounds()
        items.forEach(({ _geo }) => bounds.extend([ _geo.lng, _geo.lat ]))
        map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 })
      }

      const updateButton = document.getElementById('update-button');
      if (updateButton) { // Vérification de l'existence de l'élément
        updateButton.addEventListener('click', () => {
          const ne = map.getBounds()._ne;
          const sw = map.getBounds()._sw;

          refine({
            northEast: { lat: ne.lat, lng: ne.lng },
            southWest: { lat: sw.lat, lng: sw.lng },
          });
        });
      }
    }
  }

  async showRenderProperty(event) {
    NProgress.start();

    try {
      const markerId = event.currentTarget.id;
      const marker = event.currentTarget;

      // Enlever le préfixe 'marker-' pour obtenir l'ID de la propriété
      const propertyId = markerId.replace('marker-', '');

      // Vérifier si le marker est déjà sélectionné
      if (!marker.classList.contains('selected-mobile-marker')) {
        // S'il n'est pas sélectionné, le sélectionner
        marker.classList.add('selected-mobile-marker');
        marker.classList.remove('mobile-marker');
        sessionStorage.setItem(`marker-${markerId}`, 'selected');  // Stocker l'état sélectionné dans sessionStorage
      }

      // Procéder avec l'affichage des détails de la propriété
      const property = await this.findPropertyById(propertyId);
      let propertyHtml = this.renderProperty(property);

      // Choisissez un conteneur où vous voulez insérer la carte clonée. Par exemple, un élément avec l'ID 'propertiesContainer'.
      let container = document.getElementById('propertyDetailsContent');
      container.innerHTML = ''; // Nettoyage du contenu précédent

      // Insérez la carte dans le conteneur.
      container.insertAdjacentHTML('beforeend', propertyHtml);

      // Ajouter la class index-picture au carousel
      let carousel = container.querySelector('.swiper');
      carousel.classList.add('index-picture')

      // Modifier l'id du carousel
      const idCarouselContainer = container.querySelector(`#carouselIndexPicture-${propertyId}`)
      idCarouselContainer.id = `carouselMapPicture-${propertyId}`
      idCarouselContainer.removeAttribute("data-controller")

      const slides = container.querySelectorAll('.swiper-slide');
      // Compter le nombre de slides dans le carrousel cloné
      const hasManyImages = slides.length > 5;

      container.querySelectorAll('.swiper-slide img').forEach(image => {
        image.addEventListener('click', (event) => {
          event.preventDefault();
          this.openDetails(event, propertyId);
        });
      });

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);
      // Récupération de l'index initial de l'image à partir de l'attribut data-image-index
      const initialIndex = swiperContainer.hasAttribute('data-image-index') ? parseInt(swiperContainer.getAttribute('data-image-index'), 10) : 0;

      // Réinitialiser le plugin Swiper sur le clone
      const swiperInstance = new Swiper(container.querySelector('.swiper'), {
        loop: hasManyImages, // Permet de boucler sur les images si plusieurs images sont présentes
        spaceBetween: 10, // Espace entre les slides
        pagination: {
          el: ".swiper-pagination", // Élément pour la pagination
          type: 'fraction', // Type de pagination
        },
        navigation: {
          nextEl: ".swiper-button-next", // Bouton suivant
          prevEl: ".swiper-button-prev", // Bouton précédent
        },
        zoom: {
          maxRatio: 3, // Maximum zoom
        },
        initialSlide: initialIndex,
      });

      const originalSwiperController = this.application.getControllerForElementAndIdentifier(swiperContainer, "swiper"); // Assurez-vous que cet identifiant correspond
      if (originalSwiperController) {
        originalSwiperController.synchronizeWith(swiperInstance);
      }

      // La carte de détails est visible
      const propertyDetails = document.getElementById('propertyMobileModal');
      if (propertyDetails) {
        propertyDetails.style.display = 'block'; // Ou tout autre style nécessaire pour la rendre visible
      }

      // Ajustez la taille du conteneur de la carte si nécessaire
      this.adjustMapSizeForPropertyDetails(property);
      NProgress.done();
    } catch (error) {
      console.error("Erreur lors de la récupération de la propriété:", error);
      NProgress.done();
    };
  }

  adjustMapSizeForPropertyDetails(property) {
    // Calculer la hauteur de la fenêtre
    const windowHeight = window.innerHeight;

    // Obtenir la hauteur du `.index-picture`
    const indexPictureHeight = document.querySelector('.index-picture').offsetHeight;

    // Calculer la hauteur du `.card-body` en tenant compte du padding
    const cardBodyComputedStyle = window.getComputedStyle(document.querySelector('.card-body'));
    const cardBodyPaddingTop = parseInt(cardBodyComputedStyle.paddingTop, 10);
    const cardBodyPaddingBottom = parseInt(cardBodyComputedStyle.paddingBottom, 10);
    const cardBodyHeight = document.querySelector('.card-body').offsetHeight + cardBodyPaddingTop + cardBodyPaddingBottom;

    // Calculer la hauteur totale occupée par la carte de propriété
    const totalPropertyCardHeight = indexPictureHeight + cardBodyHeight;

    // Calculer l'espace restant pour la carte
    const remainingHeightForMap = windowHeight - totalPropertyCardHeight;

    // Ajuster la hauteur du conteneur de la carte
    const mapContainer = document.getElementById('property-index-map');
    mapContainer.style.height = `${remainingHeightForMap}px`;

    // Redimensionner la carte pour s'adapter au nouveau conteneur
    if (map) map.resize();

    // Centrer la carte sur le marker
    if (map && property._geo && property._geo.lat && property._geo.lng) {
      map.easeTo({
        center: [property._geo.lng, property._geo.lat],
        zoom: map.getZoom(), // Garde le niveau de zoom actuel
        essential: true // Cette animation est considérée comme essentielle par rapport à prefers-reduced-motion
      });
    }
  }

  renderProperty(property) {
    const locale = document.documentElement.lang;
    const loanAnnualRate = (this.loanRateValue / 100) / 12;
    const totalSellingPrice = property.condition.total_selling_price;
    const monthlyLoanPayment = ((totalSellingPrice - this.contributionAmountValue) * loanAnnualRate / (1 - (1 + loanAnnualRate) ** - (this.repaymentPeriodValue * 12)));

    const formattedMonthlyLoanPayment = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(Math.ceil(monthlyLoanPayment));

    let price;
    if (property.object === "sell") {
      price = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.total_selling_price);
    } else if (property.object === "rent") {
      price = new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.rent_price) + `${i18n.t('simple_form.per_month')}`;
    }

    const formattedPricePerMeter = Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(property.condition.price_per_meter);

    const monthlyLoanPaymentElement = monthlyLoanPayment > 0
      ? `${formattedMonthlyLoanPayment}/${i18n.t('shared.month')}`
      : `${i18n.t('meilisearch.sufficient_financial_contribution')}`;

    const monthlyLoanPaymentHtml = (this.isUserLoggedInValue && !this.isInventoryValue && !this.isInventoryCompanyValue && !this.isRentValue)
      ? `<p class="text-danger mb-0"><small>${monthlyLoanPaymentElement}</small></p>`
      : '';

    const mainRoomNumber = property.description.main_room_number;
    const bedroomNumber = property.description.bedroom_number;
    const bathroomNumber = property.description.bathroom_number;
    const dataAction = isMobileOrTablet ? "" : 'data-action="mouseenter->meilisearch-property#cardHover mouseleave->meilisearch-property#cardHover"';

    // Obtenir la racine de l'URL
    const rootUrl = window.location.origin;

    // Définir l'URL de redirection en fonction de ready_to_go_online
    const href = !property.ready_to_go_online
      ? `${rootUrl}/properties/${property.id}/edit`
      : '#';

    const dataTurbo = !property.ready_to_go_online ? 'data-turbo="false"' : '';

    const clickActionOpenDetails = !property.ready_to_go_online ? '' : 'click->meilisearch-property#openDetails';
    const clickActionScrollPosition = !property.ready_to_go_online ? '' : 'click->meilisearch-property#setScrollPosition';

    const carouselId = `carouselIndexPicture-${property.id}`;

    const defaultImageUrl = "https://res.cloudinary.com/dqicgjooj/image/upload/v1717749099/development/Seed/ooe4wifkguwqpotchohx.png";

    // Vérifiez si property.photos est défini et est un tableau
    const photos = Array.isArray(property.photos) ? property.photos : [];

    const carouselItems = (photos.length ? photos : [defaultImageUrl]).map((imageUrl, index) => {
      const isDefaultImage = imageUrl === defaultImageUrl;
      const imageClass = isDefaultImage ? 'default-image' : 'w-100 h-100';
      const containerClass = isDefaultImage ? 'default-image-container' : '';
      return `
        <div class="swiper-slide ${containerClass}">
          <div class="swiper-zoom-container">
            <img src="${imageUrl}" class="${imageClass}" alt="${i18n.t('meta.alt.property_index', { property_type_and_object: this.#formatTypeAndObject(property), price: price, zipcode: property.zipcode, city: property.city})}" data-action="${clickActionOpenDetails}" data-property-id="${property.id}">
          </div>
        </div>
      `;
    }).join('');

    const footerContent = (!property.ready_to_go_online)
      ? `<p class="footer-card text-danger"><small>${i18n.t('meilisearch.missing_elements')}</small></p>`
      : (!property.on_line)
        ? `<p class="footer-card text-warning"><small>${i18n.t('meilisearch.ad_off_line')}</small></p>`
        : (this.isInventoryValue || this.isInventoryCompanyValue || property.object === 'work')
          ? `<p class="footer-card text-success"><small>${i18n.t('meilisearch.ad_on_line')}</small></p>`
          : `<p class="footer-card text-success"><small>${
              property.company.name
                ? `${i18n.t('meilisearch.sell_by')} ${property.company.name.toUpperCase()}`
                : `${i18n.t('meilisearch.sell_by_individual')}`
            }</small></p>`;

    return `
      <div class="card-property" data-meilisearch-property-target="cards" id="${property.id}" ${dataAction} data-property-object="${property.object}">
        <div id="${carouselId}" class="index-picture" data-controller="swiper" data-swiper-property-index-value="true">
          <div class="swiper">
            <div class="swiper-wrapper">
              ${carouselItems}
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
        <div class="card-body">
          <a href="${href}" ${dataTurbo} data-property-id="${property.id}" data-action="${clickActionScrollPosition} ${clickActionOpenDetails}">
            ${property.object !== "work" ? `
              <h5 class="text-danger mb-0">${price}</h5>
              <div class="d-flex justify-content-start">
                <p class="text-danger mb-0 ${this.isUserLoggedInValue ? 'me-2' : ''}"><small>${formattedPricePerMeter}${i18n.t('simple_form.per_square_meter_html')}</small></p>
                ${monthlyLoanPaymentHtml}
              </div>
            ` : ''}
            <div class="d-flex mt-2">
            ${
              property.type_property === "land"
                ? `
                  ${property.description.bounded_ground ? `<span class="property-description">${i18n.t('meilisearch.bounded_ground_true_html')}</span>` : ''}
                  ${property.description.fully_serviced ? `<span class="property-description">${i18n.t('meilisearch.fully_serviced_true_html')}</span>` : ''}
                  ${property.description.plot_area ? `<span><strong>${property.description.plot_area}</strong> ${i18n.t('simple_form.square_meter_html')}</span>` : ''}
                `
                : `
                  ${property.description.main_room_number ? `<span class="property-description">${i18n.t('meilisearch.main_room_html', { count: mainRoomNumber })}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.main_room')}</span>`}
                  ${property.description.bedroom_number ? `<span class="property-description"><strong>${bedroomNumber}</strong> ${i18n.t('meilisearch.bedroom')}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.bedroom')}</span>`}
                  ${property.description.bathroom_number ? `<span class="property-description"><strong>${bathroomNumber}</strong> ${i18n.t('meilisearch.bathroom')}</span>` : `<span class="property-description"><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('meilisearch.bathroom')}</span>`}
                  ${property.description.living_space_area ? `<span><strong>${property.description.living_space_area}</strong> ${i18n.t('simple_form.square_meter_html')}</span>` : `<span><strong>${i18n.t('meilisearch.not_disclosed')}</strong> ${i18n.t('simple_form.square_meter_html')}</span>`}
                `
            }
            </div>
            <p class="m-0 text-start"><small>${this.#formatTypeAndObject(property)} - ${property.zipcode} ${property.city}</small></p>
            ${footerContent}
          </a>
        </div>
      </div>
    `;
  }

  observerOpenDetailsById(propertyId) {
    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle

    return new Promise((resolve, reject) => {
      // Créer un nouvel observer pour surveiller les changements dans le DOM
      const observer = new MutationObserver((mutations, observer) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((addedNode) => {
            // Recherchez à la fois dans les noeuds ajoutés et leurs descendants
            if (addedNode.nodeType === Node.ELEMENT_NODE) {
              if (addedNode.matches(`#carouselIndexPicture-${propertyId}`) || addedNode.querySelector(`#carouselIndexPicture-${propertyId}`)) {
                observer.disconnect(); // Arrêter l'observation une fois trouvé

                this.propertyDetailsListTarget.innerHTML = "";
                this.purchaseNegociationTarget.innerHTML = '';
                this.companyDetailsListTarget.innerHTML = '';
                this.fundingCompaniesTarget.innerHTML = '';
                this.suggestedCompaniesTarget.innerHTML = '';

                const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

                NProgress.start();

                fetch(`/properties/${propertyId}/show_map_partial?locale=${locale}`)
                  .then(response => {
                    return response.text();
                  })
                  .then(html => {
                    this.propertyDetailsListTarget.innerHTML = html;
                    this.openDetailsInitializeSwiper(swiperContainer, propertyId);
                    this.setupTooltip();
                    this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId);

                    // Ajouter un écouteur d'événement transitionend
                    const handleTransitionEnd = () => {
                      this.hiddenVisibiltyTurboRender();
                      // Supprimer l'écouteur après l'appel
                      this.propertyDetailsListTarget.removeEventListener('transitionend', handleTransitionEnd);
                      resolve();
                    };
                    this.propertyDetailsListTarget.addEventListener('transitionend', handleTransitionEnd);

                    // Activer la transition
                    this.propertyDetailsListTarget.classList.add('active');
                    NProgress.done();
                  })
                  .catch(error => {
                    console.error(error);
                    NProgress.done(); // Arrêter la barre de progression en cas d'erreur
                    reject(error);
                  });

                this.propertyIdValue = propertyId;
              }
            }
          });
        });
      });

      // Configurer l'observer pour surveiller l'ajout de nouveaux enfants
      const config = { childList: true, subtree: true };
      const propertyContainer = document.querySelector(".cards-properties"); // Assurez-vous que ce sélecteur correspond à votre structure DOM
      if (propertyContainer) {
        observer.observe(propertyContainer, config);
      }
    });
  }

  async openDetailsById(propertyId) {
    return new Promise((resolve, reject) => {
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

      NProgress.start(); // Démarrer la barre de progression

      fetch(`/properties/${propertyId}/show_map_partial?locale=${locale}`)
        .then(response => response.text())
        .then(html => {
          this.propertyDetailsListTarget.innerHTML = html;
        })
        .then(() => {
          this.openDetailsInitializeSwiper(swiperContainer, propertyId);
          this.setupTooltip();
          this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId);

          // Ajouter un écouteur d'événement transitionend
          const handleTransitionEnd = () => {
            this.hiddenVisibiltyTurboRender();
            // Supprimer l'écouteur après l'appel
            this.propertyDetailsListTarget.removeEventListener('transitionend', handleTransitionEnd);
            NProgress.done(); // Arrêter la barre de progression après la transition
            resolve(); // Résoudre la promesse après l'activation de la transition
          };
          this.propertyDetailsListTarget.addEventListener('transitionend', handleTransitionEnd, { once: true });

          // Activer la transition
          this.propertyDetailsListTarget.classList.add('active');
        })
        .catch(error => {
          console.error(error);
          NProgress.done(); // Arrêter la barre de progression en cas d'erreur
          reject(error);
        });

      this.propertyIdValue = propertyId;
    });
  }

  openDetails(event, propertyId) {
    event.preventDefault(); // Empêche la navigation standard

    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.propertyDetailsListTarget.innerHTML = "";
    this.purchaseNegociationTarget.innerHTML = '';
    this.companyDetailsListTarget.innerHTML = '';
    this.fundingCompaniesTarget.innerHTML = '';
    this.suggestedCompaniesTarget.innerHTML = '';

    // Récupérer l'ID de la propriété en fonction de la source de l'événement (meilisearch ou swiper)
    if (event.detail.propertyId) {
      propertyId = event.detail.propertyId;
    } else if (event.currentTarget.dataset.propertyId) {
      propertyId = event.currentTarget.dataset.propertyId;
    }

    const isSelected = sessionStorage.getItem(`marker-${propertyId}`);
    const selectedMarker = document.querySelector(`#marker-${propertyId}`);

    const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

    NProgress.start();

    fetch(`/properties/${propertyId}/show_map_partial?locale=${locale}`)
      .then(response => {
        return response.text();
      })
      .then(html => {
        this.propertyDetailsListTarget.innerHTML = html;
      })
      .then(() => {
        this.openDetailsInitializeSwiper(swiperContainer, propertyId);
        this.setupTooltip();
        this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId);

        if (!isSelected) {
          sessionStorage.setItem(`marker-${propertyId}`, 'selected');
          selectedMarker.classList.remove('default-marker', 'hovered-marker', 'selected-hovered-marker');
          selectedMarker.classList.add('selected-marker');
        }

        // Ajouter un écouteur d'événement transitionend
        const handleTransitionEnd = () => {
          this.hiddenVisibiltyTurboRender();
          // Supprimer l'écouteur après l'appel
          this.propertyDetailsListTarget.removeEventListener('transitionend', handleTransitionEnd);
        };
        this.propertyDetailsListTarget.addEventListener('transitionend', handleTransitionEnd);

        // Activer la transition
        this.propertyDetailsListTarget.classList.add('active');

        // Préparer les nouveaux paramètres à ajouter
        const params = new URLSearchParams(window.location.search);
        params.set('modalPropertyDetails', propertyId);

        // Construire la nouvelle URL avec les paramètres existants + les nouveaux
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalPropertyDetails", propertyId }, '', newUrl);

        NProgress.done();
      })
      .catch(error => {
        console.error(error);
        NProgress.done();
      });

    this.propertyIdValue = propertyId;
  }

  openModalWithImages(event) {
    // Stocker la position actuelle de défilement de la fenêtre
    this.storedScrollY = window.scrollY;

    // Figer le corps principal
    document.body.style.position = 'fixed';

    const slideIndex = parseInt(event.target.dataset.slideIndex, 10); // Récupérer et convertir l'indice du slide en nombre

    // Création et ajout de la modal au body
    const modalContainer = document.createElement('div');
    modalContainer.classList.add('modal-carousel');
    document.body.appendChild(modalContainer);

    // Créer l'arrière-plan de la modal et l'ajouter en premier
    const modalBackground = document.createElement('div');
    modalBackground.classList.add('modal-carousel-background');
    modalContainer.appendChild(modalBackground);

    // Créer le contenu de la modal
    const modalContent = document.createElement('div');
    modalContent.classList.add('modal-carousel-content');
    modalContainer.appendChild(modalContent);

    // Ajouter le bouton de fermeture
    const closeButton = document.createElement('button');
    closeButton.classList.add('modal-carousel-close');
    closeButton.setAttribute('aria-label', 'Fermer');
    closeButton.innerHTML = '<i class="fas fa-times"></i>';
    modalContent.appendChild(closeButton);

    // Ajouter le conteneur pour le carousel Swiper
    const swiperContainer = document.createElement('div');
    swiperContainer.classList.add('swiper-container');
    modalContent.appendChild(swiperContainer);

    const swiperWrapper = document.createElement('div');
    swiperWrapper.classList.add('swiper-wrapper');
    swiperContainer.appendChild(swiperWrapper);

    // Préparer et afficher les images dans la modal
    const originalCarouselContainer = event.currentTarget.closest('.swiper');
    const images = originalCarouselContainer.querySelectorAll('.swiper-slide img');

    images.forEach((image, index) => {
        const swiperSlide = document.createElement('div');
        swiperSlide.classList.add('swiper-slide');

        const zoomContainer = document.createElement('div');
        zoomContainer.classList.add('swiper-zoom-container');

        const imgClone = image.cloneNode(true);
        imgClone.removeAttribute('data-action');
        imgClone.setAttribute('data-slide-index', index);

        zoomContainer.appendChild(imgClone);
        swiperSlide.appendChild(zoomContainer);

        swiperWrapper.appendChild(swiperSlide);
    });

    // Ajouter la pagination et la navigation
    const swiperPagination = document.createElement('div');
    swiperPagination.classList.add('swiper-pagination');
    swiperContainer.appendChild(swiperPagination);

    const swiperButtonNext = document.createElement('div');
    swiperButtonNext.classList.add('swiper-button-next');
    swiperContainer.appendChild(swiperButtonNext);

    const swiperButtonPrev = document.createElement('div');
    swiperButtonPrev.classList.add('swiper-button-prev');
    swiperContainer.appendChild(swiperButtonPrev);

    // Initialiser Swiper
    const modalSwiper = new Swiper(swiperContainer, {
        loop: images.length > 1,
        spaceBetween: 10,
        pagination: {
            el: swiperPagination,
            type: 'fraction',
        },
        navigation: {
            nextEl: swiperButtonNext,
            prevEl: swiperButtonPrev,
        },
        zoom: {
            maxRatio: 3,
        },
        initialSlide: slideIndex,
    });

    // Écouteurs pour les éléments interactifs
    closeButton.addEventListener('click', this.closeModalWithImages.bind(this));
    modalBackground.addEventListener('click', this.closeModalWithImages.bind(this));
  }

  closeModalWithImages(event) {
      event.preventDefault();

      const modalCarousel = document.querySelector('.modal-carousel');
      if (modalCarousel) {
          modalCarousel.remove();
      }

      // Réafficher le corps principal
      document.body.style.position = 'inherit';
      document.body.classList.remove('no-scroll');

      window.scrollTo(0, this.storedScrollY);
  }

  openQuoteSuggestedCompaniesByEvent(event) {
    event.preventDefault();

    const propertyId = event.currentTarget.dataset.propertyId;
    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.fundingCompaniesTarget.innerHTML = ""

    const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

    // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde
    NProgress.start();

    if (this.isUserLoggedInValue) {
      fetch(`/properties/${propertyId}/quotes/suggested_companies?locale=${locale}&fetch=suggested&turbo_stream=true`)
      .then(response => {
        // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
        return response.text();
      })
      .then(html => {
        this.suggestedCompaniesTarget.innerHTML = html;
        this.openQuoteSuggestedCompaniesInitializeSwiper(swiperContainer, propertyId)
      })
      .then(() => {
        // this.stopLoading();
        this.setupTooltip();
        this.restoreMeta("meta.titles.quote_suggested_companies", "meta.descriptions.quote_suggested_companies", "meta.keywords.quote_suggested_companies", propertyId)

        // Active la transition après l'insertion du HTML
        this.suggestedCompaniesTarget.classList.add('active');

        // Préparer les nouveaux paramètres à ajouter
        const params = new URLSearchParams(window.location.search);
        params.set('modalSuggested', propertyId);

        // Construire la nouvelle URL avec les paramètres existants + les nouveaux
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalSuggested", propertyId }, '', newUrl);
        NProgress.done();
      })
      .catch(error => {
        // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
        console.error(error);
        NProgress.done();
        // this.stopLoading(); // Arrêter l'animation en cas d'erreur
      });
    }
  }

  async openQuoteSuggestedCompaniesById(propertyId) {
    return new Promise((resolve, reject) => {
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
      this.fundingCompaniesTarget.innerHTML = ""

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

      NProgress.start();

      if (this.isUserLoggedInValue) {
        fetch(`/properties/${propertyId}/quotes/suggested_companies?locale=${locale}&fetch=suggested`)
          .then(response => response.text())
          .then(html => {
            this.suggestedCompaniesTarget.innerHTML = html;
            this.openQuoteSuggestedCompaniesInitializeSwiper(swiperContainer, propertyId);
          })
          .then(() => {
            this.setupTooltip();
            this.restoreMeta("meta.titles.quote_suggested_companies", "meta.descriptions.quote_suggested_companies", "meta.keywords.quote_suggested_companies", propertyId);

            // Active la transition après l'insertion du HTML
            this.suggestedCompaniesTarget.classList.add('active');

            // Résoudre la promesse après l'activation de la transition
            this.suggestedCompaniesTarget.addEventListener('transitionend', () => {
              NProgress.done();
              resolve();
            }, { once: true });
          })
          .catch(error => {
            console.error(error);
            NProgress.done();
            reject(error);
          });
      } else {
        // Préparer les nouveaux paramètres à ajouter
        const params = new URLSearchParams(window.location.search);
        params.set('modalSuggested', propertyId);

        // Construire la nouvelle URL avec les paramètres existants + les nouveaux
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        sessionStorage.setItem("returnToAfterLogin", newUrl);
        window.location.href = '/login';
        resolve(); // Résoudre la promesse dans le cas où l'utilisateur n'est pas connecté
      }
    });
  }

  openQuoteFundingCompanies(event) {
    event.preventDefault(); // Empêche la navigation standard

    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.fundingCompaniesTarget.innerHTML = ""

    const propertyId = event.currentTarget.dataset.propertyId;
    const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

    // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde
    NProgress.start();

    if (this.isUserLoggedInValue) {
      fetch(`/properties/${propertyId}/quotes/funding_companies?locale=${locale}&fetch=suggested&turbo_stream=true`)
        .then(response => {
          // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
          return response.text();
        })
        .then(html => {
          this.fundingCompaniesTarget.innerHTML = html;
          this.openQuoteFundingCompaniesInitializeSwiper(swiperContainer, propertyId)
        })
        .then(() => {
          // this.stopLoading();
          this.setupTooltip();
          this.restoreMeta("meta.titles.quotes_funding_companies", "meta.descriptions.quotes_funding_companies", "meta.keywords.quotes_funding_companies", propertyId)

          this.propertyDetailsListTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

          // Active la transition après l'insertion du HTML
          this.fundingCompaniesTarget.classList.add('active');

          // Préparer les nouveaux paramètres à ajouter
          const params = new URLSearchParams(window.location.search);
          params.set('modalFunding', propertyId);

          // Construire la nouvelle URL avec les paramètres existants + les nouveaux
          const newUrl = `${window.location.pathname}?${params.toString()}`;
          window.history.pushState({ modalOpened: true, modalType: "modalFunding", propertyId }, '', newUrl);
          NProgress.done();
        })
        .catch(error => {
          // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
          console.error(error);
          NProgress.done();
          // this.stopLoading(); // Arrêter l'animation en cas d'erreur
        });
    } else {
      // Préparer les nouveaux paramètres à ajouter
      const params = new URLSearchParams(window.location.search);
      params.set('modalFunding', propertyId);

      // Construire la nouvelle URL avec les paramètres existants + les nouveaux
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      // window.history.pushState({ modalOpened: true, modalType: "modalFunding", propertyId }, '', newUrl);

      // Mémoriser l'URL actuelle ou les paramètres nécessaires avant de rediriger
      sessionStorage.setItem("returnToAfterLogin", newUrl);
      window.location.href = '/login';
    }
  }

  async openQuoteFundingCompaniesById(propertyId) {
    return new Promise((resolve, reject) => {
      this.fundingCompaniesTarget.innerHTML = "";
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);

      NProgress.start();

      if (this.isUserLoggedInValue) {
        fetch(`/properties/${propertyId}/quotes/funding_companies?locale=${locale}&fetch=suggested`)
          .then(response => response.text())
          .then(html => {
            this.fundingCompaniesTarget.innerHTML = html;
            this.openQuoteFundingCompaniesInitializeSwiper(swiperContainer, propertyId);
          })
          .then(() => {
            this.setupTooltip();
            this.restoreMeta("meta.titles.quotes_funding_companies", "meta.descriptions.quotes_funding_companies", "meta.keywords.quotes_funding_companies", propertyId);

            this.propertyDetailsListTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

            // Active la transition après l'insertion du HTML
            this.fundingCompaniesTarget.classList.add('active');

            // Résoudre la promesse après l'activation de la transition
            this.fundingCompaniesTarget.addEventListener('transitionend', () => {
              NProgress.done();
              resolve();
            }, { once: true });
          })
          .catch(error => {
            console.error(error);
            NProgress.done();
            reject(error);
          });
      } else {
        // Préparer les nouveaux paramètres à ajouter
        const params = new URLSearchParams(window.location.search);
        params.set('modalFunding', propertyId);

        // Construire la nouvelle URL avec les paramètres existants + les nouveaux
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        sessionStorage.setItem("returnToAfterLogin", newUrl);
        window.location.href = '/login';
        resolve(); // Résoudre la promesse dans le cas où l'utilisateur n'est pas connecté
      }
    });
  }

  // Méthode pour gérer l'événement de clic et appeler openDetailsById
  openCompanyDetailsByEvent(event) {
    event.preventDefault();

    const companyId = event.currentTarget.getAttribute('data-company-id');
    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.companyDetailsListTarget.innerHTML = "";

    // Stocker l'URL actuelle
    sessionStorage.setItem("previousUrl", window.location.href);
    NProgress.start();

    fetch(`/companies/${companyId}/show_partial?locale=${locale}&fetch=company`)
    .then(response => {
      return response.text();
    })
    .then(html => {
      this.companyDetailsListTarget.innerHTML = html;
    })
    .then(() => {
      // this.stopLoading();
      this.setupTooltip();
      this.restoreMetaCompany("meta.titles.company_show", "meta.descriptions.company_show", "meta.keywords.company_show", companyId)

      this.fundingCompaniesTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

      // Active la transition après l'insertion du HTML
      this.companyDetailsListTarget.classList.add('active');

      // Préparer les nouveaux paramètres à ajouter
      const params = new URLSearchParams(window.location.search);
      params.set('modalCompany', companyId);

      // Construire la nouvelle URL avec les paramètres existants + les nouveaux
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.pushState({ modalOpened: true, modalType: "modalCompany", companyId }, '', newUrl);
      NProgress.done();
    })
    .catch(error => {
      console.error("Fetch error:", error.message); // Log uniquement le message d'erreur
      console.log(error); // Log the entire error object for debugging purposes
      NProgress.done();
    });
  }

  async openCompanyDetailsById(companyId) {
    return new Promise((resolve, reject) => {
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
      this.companyDetailsListTarget.innerHTML = "";

      // Stocker l'URL actuelle
      sessionStorage.setItem("previousUrl", window.location.href);
      NProgress.start();

      fetch(`/companies/${companyId}/show_partial?locale=${locale}&fetch=company`)
        .then(response => response.text())
        .then(html => {
          this.companyDetailsListTarget.innerHTML = html;
        })
        .then(() => {
          this.setupTooltip();
          this.restoreMetaCompany("meta.titles.company_show", "meta.descriptions.company_show", "meta.keywords.company_show", companyId);

          this.fundingCompaniesTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

          // Active la transition après l'insertion du HTML
          this.companyDetailsListTarget.classList.add('active');

          // Résoudre la promesse après l'activation de la transition
          this.companyDetailsListTarget.addEventListener('transitionend', () => {
            NProgress.done();
            resolve();
          }, { once: true });
        })
        .catch(error => {
          console.error("Fetch error:", error.message); // Log uniquement le message d'erreur
          console.log(error); // Log the entire error object for debugging purposes
          NProgress.done();
          reject(error);
        });
    });
  }

  openPurchaseNegociation(event) {
    event.preventDefault(); // Empêche la navigation standard

    const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
    this.purchaseNegociationTarget.innerHTML = ""

    const propertyId = event.currentTarget.dataset.propertyId;
    const formType = event.currentTarget.dataset.formType;

    const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);
    NProgress.start();
    // let loadingTimeout = setTimeout(() => this.startLoading(), 500); // Démarrer l'animation de chargement après 1 seconde

    if (this.isUserLoggedInValue) {
      fetch(`/properties/${propertyId}/purchase_negociations/new?locale=${locale}&formType=${formType}`)
        .then(response => {
          if (response.redirected) {
            window.location.href = response.url;  // Si une redirection est effectuée par le serveur, redirigez le navigateur
            return;
          }
          // clearTimeout(loadingTimeout); // Annuler l'animation de chargement si le fetch est rapide
          return response.text();  // Continuez comme avant si ce n'est pas une redirection
        })
        .then(html => {
          this.purchaseNegociationTarget.innerHTML = html;
          this.openPurchaseNegociationInitializeSwiper(swiperContainer, propertyId)
        })
        .then(() => {
          // this.stopLoading();
          this.setupTooltip();
          this.restoreMeta("meta.titles.purchase_negociation_new", "meta.descriptions.purchase_negociation_new", "meta.keywords.purchase_negociation_new", propertyId)

          this.propertyDetailsListTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

          // Active la transition après l'insertion du HTML
          this.purchaseNegociationTypeForm(formType)
          this.purchaseNegociationTarget.classList.add('active');

          // Préparer les nouveaux paramètres à ajouter
          const params = new URLSearchParams(window.location.search);
          params.set('modalPurchaseNegociation', propertyId);

          // Construire la nouvelle URL avec les paramètres existants + les nouveaux
          const newUrl = `${window.location.pathname}?${params.toString()}`;
          window.history.pushState({ modalOpened: true, modalType: "modalPurchaseNegociation", propertyId }, '', newUrl);
          NProgress.done();
        })
        .catch(error => {
          // clearTimeout(loadingTimeout); // Assurez-vous d'annuler l'animation de chargement en cas d'erreur
          console.error(error);
          NProgress.done();
          // this.stopLoading(); // Arrêter l'animation en cas d'erreur
        });
    } else {
      // Préparer les nouveaux paramètres à ajouter
      const params = new URLSearchParams(window.location.search);
      params.set('modalPurchaseNegociation', propertyId);

      // Construire la nouvelle URL avec les paramètres existants + les nouveaux
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      // window.history.pushState({ modalOpened: true, modalType: "modalPurchaseNegociation", propertyId }, '', newUrl);

      // Mémoriser l'URL actuelle ou les paramètres nécessaires avant de rediriger
      sessionStorage.setItem("returnToAfterLogin", newUrl);
      window.location.href = '/login';
    }
  }

  async openPurchaseNegociationById(propertyId) {
    return new Promise((resolve, reject) => {
      const locale = document.querySelector("html").lang || "fr-FR"; // Récupérer la locale actuelle
      this.purchaseNegociationTarget.innerHTML = "";

      const swiperContainer = document.querySelector(`#carouselIndexPicture-${propertyId}`);
      NProgress.start();

      if (this.isUserLoggedInValue) {
        fetch(`/properties/${propertyId}/purchase_negociations/new?locale=${locale}`)
          .then(response => response.text())
          .then(html => {
            this.purchaseNegociationTarget.innerHTML = html;
            this.openPurchaseNegociationInitializeSwiper(swiperContainer, propertyId);
          })
          .then(() => {
            this.setupTooltip();
            this.restoreMeta("meta.titles.purchase_negociation_new", "meta.descriptions.purchase_negociation_new", "meta.keywords.purchase_negociation_new", propertyId);

            this.propertyDetailsListTarget.addEventListener('touchmove', this.preventDefaultTouch, { passive: false });

            // Active la transition après l'insertion du HTML
            this.purchaseNegociationTarget.classList.add('active');

            // Résoudre la promesse après l'activation de la transition
            this.purchaseNegociationTarget.addEventListener('transitionend', () => {
              NProgress.done();
              resolve();
            }, { once: true });
          })
          .catch(error => {
            console.error(error);
            NProgress.done();
            reject(error);
          });
      } else {
        // Préparer les nouveaux paramètres à ajouter
        const params = new URLSearchParams(window.location.search);
        params.set('modalPurchaseNegociation', propertyId);

        // Construire la nouvelle URL avec les paramètres existants + les nouveaux
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        sessionStorage.setItem("returnToAfterLogin", newUrl);
        window.location.href = '/login';
        resolve(); // Résoudre la promesse dans le cas où l'utilisateur n'est pas connecté
      }
    });
  }

  purchaseNegociationTypeForm(formType) {
    const visitForm = document.getElementById('visitForm');
    const contactForm = document.getElementById('contactForm');

    if (visitForm && contactForm) {
      if (formType === "visitForm") {
        visitForm.classList.remove("d-none");
        contactForm.classList.add("d-none");
      } else if (formType === "contactForm") {
        visitForm.classList.add("d-none");
        contactForm.classList.remove("d-none");
      }
    }
  }

  closeDetails(event) {
    this.showVisibiltyTurboRender();

    // Gestion des transitions et URL
    this.updateActiveStatusAndURL(event);
  }

  updateActiveStatusAndURL(event) {
    const propertyId = this.propertyIdValue;
    let newUrl = new URL(window.location);
    const params = newUrl.searchParams;

    if (this.companyDetailsListTarget.classList.contains("active")) {
      this.deactivateSection(this.companyDetailsListTarget, 'modalCompany', params);
      this.restoreMeta("meta.titles.quotes_funding_companies", "meta.descriptions.quotes_funding_companies", "meta.keywords.quotes_funding_companies", propertyId)

      if (event.type !== 'popstate') {
        const params = new URLSearchParams(window.location.search);
        // Supprime le paramètre 'modalCompany' s'il existe
        params.delete('modalCompany');

        // Utilise le pathname et les paramètres modifiés pour construire la nouvelle URL
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalFunding", propertyId }, '', newUrl);
      }
    } else if (this.suggestedCompaniesTarget.classList.contains("active")) {
      this.deactivateSection(this.suggestedCompaniesTarget, 'modalSuggested', params);
      this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId)

      if (event.type !== 'popstate') {
        const params = new URLSearchParams(window.location.search);
        // Supprime le paramètre 'modalSuggested' s'il existe
        params.delete('modalSuggested');

        // Utilise le pathname et les paramètres modifiés pour construire la nouvelle URL
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalPropertyDetails", propertyId }, '', newUrl);
      }
    } else if (this.fundingCompaniesTarget.classList.contains("active")) {
      this.deactivateSection(this.fundingCompaniesTarget, 'modalFunding', params);
      this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId)

      if (event.type !== 'popstate') {
        const params = new URLSearchParams(window.location.search);
        // Supprime le paramètre 'modalFunding' s'il existe
        params.delete('modalFunding');

        // Utilise le pathname et les paramètres modifiés pour construire la nouvelle URL
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalPropertyDetails", propertyId }, '', newUrl);
      }
    } else if (this.purchaseNegociationTarget.classList.contains("active")) {
      this.deactivateSection(this.purchaseNegociationTarget, 'modalPurchaseNegociation', params);
      this.restoreMeta("meta.titles.property_show", "meta.descriptions.property_show", "meta.keywords.property_show", propertyId)
      if (event.type !== 'popstate') {
        const params = new URLSearchParams(window.location.search);
        // Supprime le paramètre 'modalPurchaseNegociation' s'il existe
        params.delete('modalPurchaseNegociation');

        // Utilise le pathname et les paramètres modifiés pour construire la nouvelle URL
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({ modalOpened: true, modalType: "modalPropertyDetails", propertyId }, '', newUrl);
      }
    } else {
      this.deactivateSection(this.propertyDetailsListTarget, 'modalPropertyDetails', params);




      // Réactiver le défilement sur le body seulement si la map n'est pas ouverte
      if (!this.isMapOpen) {
        document.body.style.overflow = '';
      }
      this.restoreMeta("meta.titles.properties_index", "meta.descriptions.properties_index", "meta.keywords.properties_index")
      if (event.type !== 'popstate') {
        // Utilise le pathname et les paramètres modifiés pour construire la nouvelle URL
        const newUrl = `${window.location.pathname}`;
        window.history.pushState({ }, '', newUrl);
      }
    }
  }

  deactivateSection(target, paramName, params) {
    setTimeout(() => {
      target.classList.remove('active');
      setTimeout(() => {
        target.innerHTML = '';
        params.delete(paramName);
      }, 250);
    }, 250);
  }

  closeDetailsNavigator() {
    // let newUrl = new URL(window.location);
    // const params = newUrl.searchParams;

    // if (this.fundingCompaniesTarget.classList == "active") {
    //   params.delete('modalFunding');
    //   this.fundingCompaniesTarget.classList.remove('active'); // Désactive la transition
    // } else if (this.purchaseNegociationTarget.classList == "active") {
    //   params.delete('modalPurchaseNegociation');
    //   this.purchaseNegociationTarget.classList.remove('active'); // Désactive la transition
    // } else {
    //   params.delete('modalPropertyDetails');
    //   this.propertyDetailsListTarget.classList.remove('active'); // Désactive la transition
    // }
  }

  updateSimulator(event) {
    const formData = new FormData(event.target);

    // Champs de formulaire ont des noms correctement mappés.
    this.loanRateValue = Number(formData.get('user[loan_rate_simulator]'));
    this.contributionAmountValue = Number(formData.get('user[contribution_amount_simulator]'));
    this.repaymentPeriodValue = Number(formData.get('user[repayment_period_simulator]'));

    // Rafraîchir les widgets pour refléter les nouvelles valeurs
    this.search.refresh();
  }

  renderStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      areHitsSorted,
      nbSortedHits,
      query,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    let count = '';

    if (areHitsSorted) {
      if (nbSortedHits > 1) {
        count = `${nbSortedHits} ${i18n.t('meilisearch.relevant_results')}`;
      } else if (nbSortedHits === 1) {
        count = `${i18n.t('meilisearch.one_relevant_result')}`;
      } else {
        count = `${i18n.t('meilisearch.no_relevant_result')}`;
      }
      count += ` ${i18n.t('meilisearch.sorted_on')} ${nbHits}`;
    } else {
      if (nbHits > 1) {
        count += `${nbHits} ${i18n.t('meilisearch.properties')}`;
      } else if (nbHits === 1) {
        count += `${i18n.t('meilisearch.one_property')}`;
      } else {
        count += `${i18n.t('meilisearch.no_property')}`;
      }
    }

    if (nbSortedHits > 1) {
      widgetParams.container.innerHTML = `
      <span class="MyCustomStatsText">${i18n.t('shared.display')} ${count}
      ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</span>
    `;
    } else {
      widgetParams.container.innerHTML = `
        <span class="MyCustomStatsText">${i18n.t('shared.display')} ${count}
        ${query ? `${i18n.t('meilisearch.for')} <q>${query}</q>` : ''}</span>
      `;
    }
  };

  renderTitleStats = (renderOptions, isFirstRender) => {
    const {
      nbHits,
      widgetParams,
    } = renderOptions;

    if (isFirstRender) {
      return;
    }

    if (this.isInventoryValue || this.isInventoryCompanyValue) {
      const titleKey = 'meilisearch.properties_to_inventory';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
      } else if (this.isBuyValue) {
      const titleKey = 'meilisearch.properties_to_buy';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
      } else if (this.isRentValue){
      const titleKey = 'meilisearch.properties_to_rent';
      const title = i18n.t(titleKey, { count: nbHits });
      widgetParams.container.innerHTML = `<h1>${title}</h1>`;
    }
  };

  #formatTypeAndObject(property) {
    const typeProperty = property.type_property;
    const object = property.object;
    if (typeProperty === "house") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.house_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.house_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.house_for_work')}`;
      }
    } else if (typeProperty === "apartment") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.apartment_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.apartment_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.apartment_for_work')}`;
      }
    } else if (typeProperty === "land") {
      if (object === "sell") {
        return `${i18n.t('meilisearch.land_for_sale')}`;
      } else if (object === "rent") {
        return `${i18n.t('meilisearch.land_for_rent')}`;
      } else if (object === "work") {
        return `${i18n.t('meilisearch.land_for_work')}`;
      }
    }
    return `${i18n.t('meilisearch.not_disclosed')}`; // Default return value if none of the conditions are met
  }
}
